import { SvgIcon, styled } from "@mui/material";

export const BakusIconWrapper = styled(SvgIcon, {
  name: "BakusIconWrapper",
})<{ denied: boolean }>(({ theme, denied }) => ({
  marginBottom: -8,
  fontSize: 150,
  color: denied
    ? theme.palette.error.main
    : theme.palette.grey[theme.palette.mode === "light" ? 200 : 900],
}));
