import { getFirestore, initializeFirestore } from "firebase/firestore";

import Config from "./config";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";
import { initializeApp } from "firebase/app";

const firebaseApp = initializeApp(Config.firebase);

// @ts-ignore
if (window?.Cypress) {
  console.log(" >>> Force cypress config <<<");
  initializeFirestore(firebaseApp, {
    experimentalForceLongPolling: true,
    // ssl: false,
  });
}

export const fireDatabase = getFirestore(firebaseApp);
export const fireFunction = getFunctions(firebaseApp);
export const fireAuth = getAuth(firebaseApp);
export const fireStorage = getStorage(firebaseApp);

export default firebaseApp;
