import { ButtonProps } from "@mui/material";
import { LoadingButtonProps } from "@mui/lab";

export const hasColor = (color: ButtonProps["color"]) => {
  return !!color && color !== "inherit";
};

const feedbackColor = ["warning", "error", "success", "info"];

export const isButtonFeedback = (color: ButtonProps["color"]) => {
  return hasColor(color) && feedbackColor.includes(color as string);
};

/**
 * Returns the size that a _Circular Progress Icon_ should have (as a standalone icon)
 * based on its parent Icon Button size prop.
 */
export const getCircularProgressSize = (size: ButtonProps["size"]) => {
  switch (size) {
    case "large":
      return 28;
    case "medium":
    default:
      return 24;
    case "small":
      return 18;
  }
};

/**
 * Returns the size that a _Circular Progress Icon_ should have (inside a regular Button)
 * based on its parent Button size prop.
 */
export const getCircularProgressSizeInsideButton = (
  size: ButtonProps["size"],
) => {
  switch (size) {
    case "large":
    case "medium":
    default:
      return 16;
    case "small":
      return 12;
  }
};

/**
 * Returns a small _right_ or _left_ CSS offset based on a Button size and an icon placement
 * (defined by a _startIcon_ or _endIcon_) to correct the _Circular Progress Icon_ position
 * to be at the exact same place as the icon it replaces for the loading animation.
 */
export const getBetterCircularProgressIconPlacement = (
  size: ButtonProps["size"],
  position: LoadingButtonProps["loadingPosition"],
): React.CSSProperties => {
  const isRight = position === "end";
  const isLeft = position === "start";

  switch (size) {
    case "large":
      return {
        right: isRight ? 21 : 0,
        left: isLeft ? 21 : 0,
      };
    case "medium":
    default:
      return {
        right: isRight ? 14 : 0,
        left: isLeft ? 14 : 0,
      };
    case "small":
      return {
        right: isRight ? 11.5 : 0,
        left: isLeft ? 11.5 : 0,
      };
  }
};

/**
 * Returns The loading position ("start" | "end" | "center") of a LoadingButtonIcon
 * based on the _startIcon_ and _endIcon_ parent Button props.
 */
export const getButtonLoadingIconPosition = ({
  startIcon,
  endIcon,
}: {
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
}): LoadingButtonProps["loadingPosition"] => {
  if (startIcon) return "start";
  if (endIcon) return "end";
  return "center";
};
