import { collection, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";

import { ATOMS } from "../../recoil/atom";
import { IToolModel } from "../../types/tool";
import { createSnackBarContent } from "../global/useSnackBar";
import { fireDatabase } from "../../config/firebase";
import { needRequestCollections } from "../../constants/access";
import useNeedRequestVisibilityHelper from "../data/useNeedRequestVisibilityHelper";
import { useSetAtom } from "jotai";
import { useTranslation } from "react-i18next";

const collectionRequired = [needRequestCollections.TOOLS_MODEL];

const useToolsModel = () => {
  const { t } = useTranslation(["snackbar"]);

  const popSnackbar = useSetAtom(ATOMS.SNACKBAR);
  const setToolsModelList = useSetAtom(ATOMS.TOOL_MODEL_LIST);

  const [unSubscribe, setUnSubscribe] = useState<(() => void) | null>(null);

  const canLoadData = useNeedRequestVisibilityHelper(() => {
    unSubscribe && unSubscribe();
  }, collectionRequired);

  useEffect(() => {
    if (canLoadData) {
      const unsub = onSnapshot(
        collection(fireDatabase, "tools_model"),
        (snap) => {
          const dataModels: IToolModel[] = [];
          snap.docs.forEach((doc) => {
            const data = doc.data();
            if (data) {
              dataModels.push(data as IToolModel);
            }
          });
          setToolsModelList(dataModels);
        },
        (err) => {
          console.error(err);
          popSnackbar(
            createSnackBarContent({
              content: t("snackbar:request_tool_error"),
              severity: "error",
            }),
          );
          setToolsModelList([]);
        },
      );

      setUnSubscribe(() => unsub);
      return () => unsub();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canLoadData]);
};

export default useToolsModel;
