import { SnackbarKey, SnackbarOrigin, useSnackbar } from "notistack";
import { useCallback, useEffect } from "react";

import { ATOMS } from "../recoil/atom";
import Close from "@mui/icons-material/Close";
import { ISnackbarContent } from "../types/snackbar";
import { IconButton } from "@mui/material";
import { isValidNotEmptyString } from "../utils/string";
import { useAtomValue } from "jotai";

const getOrigin = (origin: string | undefined): SnackbarOrigin => {
  const anchor: SnackbarOrigin = {
    horizontal: "right",
    vertical: "bottom",
  };
  switch (origin) {
    case "bottom-left":
      anchor.vertical = "bottom";
      anchor.horizontal = "left";
      break;
    case "bottom-center":
      anchor.vertical = "bottom";

      anchor.horizontal = "center";
      break;
    case "bottom-right":
      anchor.vertical = "bottom";
      anchor.horizontal = "right";
      break;
    case "top-left":
      anchor.vertical = "top";
      anchor.horizontal = "left";
      break;
    case "top-center":
      anchor.vertical = "top";
      anchor.horizontal = "center";
      break;
    case "top-right":
      anchor.vertical = "top";
      anchor.horizontal = "right";
      break;
    default:
      anchor.vertical = "bottom";
      anchor.horizontal = "right";
      break;
  }

  return anchor;
};

const SnackBar = () => {
  const snackBar = useAtomValue(ATOMS.SNACKBAR);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const popSnackbar = useCallback((snackBar: Partial<ISnackbarContent>) => {
    if (isValidNotEmptyString(snackBar.content)) {
      const action = (key: SnackbarKey) => (
        <IconButton onClick={() => closeSnackbar(key)} size="large">
          <Close />
        </IconButton>
      );
      enqueueSnackbar(snackBar.content, {
        variant: snackBar.severity,
        autoHideDuration: snackBar.duration,
        action,
        anchorOrigin: getOrigin(snackBar.position),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Array.isArray(snackBar)) {
      snackBar.forEach((snb: Partial<ISnackbarContent>) => popSnackbar(snb));
    } else {
      popSnackbar(snackBar);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snackBar]);

  return null;
};

export default SnackBar;
