import {
  AppBar,
  AppBarProps,
  IconButton,
  Toolbar,
  Typography,
  styled,
} from "@mui/material";

export const FleetAppBar = styled(AppBar, {
  name: "FleetAppBar",
})<AppBarProps>(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

export const AppTitle = styled(Typography, {
  name: "AppTitle",
})(
  ({ theme }) => `
  flex-grow: 1;
  color: ${theme.palette.primary.contrastText};
`,
);

export const AppToolbar = styled(Toolbar, {
  name: "AppToolbar",
})(
  ({ theme }) => `
padding: ${theme.spacing(0, 2)};
`,
);

export const MenuIcon = styled(IconButton, {
  name: "MenuIcon",
})(
  ({ theme }) => `
  margin-right: ${theme.spacing(1)};
`,
);
