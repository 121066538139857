import { AtLeast } from "../../utils/type";
import { ISnackbarContent } from "../../types/snackbar";

export const createSnackBarContent = ({
  content = "",
  severity = "info",
  duration = 6000,
  position = "bottom-right",
}: AtLeast<ISnackbarContent, "content">) => {
  return {
    content,
    duration,
    position,
    severity,
  };
};
