import {
  Box,
  Button,
  ButtonProps,
  CircularProgress,
  IconButton,
  IconButtonProps,
} from "@mui/material";
import {
  LoadingButton as LoadingButtonFromMui,
  LoadingButtonProps as LoadingButtonPropsFromMui,
} from "@mui/lab";
import {
  getBetterCircularProgressIconPlacement,
  getButtonLoadingIconPosition,
  getCircularProgressSize,
  getCircularProgressSizeInsideButton,
  hasColor,
} from "./functions";

import { ICustomButtonProps } from "./CustomButton";
import React from "react";
import { buttonCommonStyles } from "./index";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
  root: {
    ...buttonCommonStyles.root,
  },
  wrapper: {
    ...buttonCommonStyles.wrapper,
  },
  button: {
    // ...buttonCommonStyles.button
  },
  iconButton: {
    transition: buttonCommonStyles.iconButton.transition,
  },
  loadingButton: {
    // ...buttonCommonStyles.loadingButton
  },
}));

export interface ILoadingButtonProps {
  children?: React.ReactNode;
  loading: boolean;
  loadingType?: "circular" | "linear"; // TODO: Add linear progress button
}

export type ILoadingButtonPropsExtended =
  | (ILoadingButtonProps & ICustomButtonProps)
  | (ILoadingButtonProps & ICustomButtonProps & LoadingButtonPropsFromMui);
// & (CircularProgressProps | LinearProgressProps);

/**
 * Icon type loading button
 */
const RenderLoadingIconButton = ({
  loading,
  ...props
}: ILoadingButtonPropsExtended) => {
  const classes = useStyles();

  return (
    <IconButton
      className={clsx(
        props.className,
        classes.iconButton,
        loading && classes.loadingButton,
      )}
      disabled={props.disabled || loading}
      {...(props as IconButtonProps)}
    >
      <>
        {loading ? (
          <CircularProgress
            size={getCircularProgressSize(props.size)}
            color={
              hasColor(props.color)
                ? (props.color as ButtonProps["color"])
                : "primary"
            }
          />
        ) : (
          props.icon
        )}
      </>
    </IconButton>
  );
};

/**
 * Regular type loading button
 */
const RenderLoadingRegularButton = (props: ILoadingButtonPropsExtended) => {
  const classes = useStyles();

  const loadingPosition = getButtonLoadingIconPosition({
    startIcon: props.startIcon,
    endIcon: props.endIcon,
  });

  const circularProgressIconAdjustment = getBetterCircularProgressIconPlacement(
    props.size,
    loadingPosition,
  );

  const { loading, loadingType, ...regularButtonProps } = props;

  return loading ? (
    <LoadingButtonFromMui
      {...(props as LoadingButtonPropsFromMui)}
      loading={loading}
      loadingPosition={loadingPosition}
      loadingIndicator={
        <CircularProgress
          color="inherit"
          size={getCircularProgressSizeInsideButton(props.size)}
        />
      }
      className={clsx(props.className, classes.button, classes.loadingButton)}
      sx={{
        "& .MuiLoadingButton-loadingIndicatorEnd": {
          right: circularProgressIconAdjustment.right,
        },
        "& .MuiLoadingButton-loadingIndicatorStart": {
          left: circularProgressIconAdjustment.left,
        },
        ...props.sx,
      }}
    >
      {props.children}
    </LoadingButtonFromMui>
  ) : (
    <Button
      {...regularButtonProps}
      variant={props.variant as ButtonProps["variant"]}
      className={clsx(props.className, classes.button)}
      sx={{ ...props.sx }}
    >
      {props.children}
    </Button>
  );
};

/**
 * Custom loading button component
 * @example
 *   // Button Props
 * - color="primary"
 * - variant="contained"
 * - size="large"
 * - onClick={function}
 * - disabled={boolean}
 *   // Loading Button Props
 * - loading={boolean}
 * - loadingType="circular"
 *   // Tooltip Props
 * - tooltipTitle="Tooltip title"
 * - tooltipPlacement="Tooltip placement"
 *   // Icon Props
 * - icon={<Icon fontSize="inherit" />}
 *    | startIcon={<Icon />}
 *    | endIcon={<Icon className={classes.rightIcon} />}
 *   // Other
 * - ...ButtonProps (@mui/material/Button)
 */
const CustomLoadingButton = ({
  children,
  ...props
}: ILoadingButtonPropsExtended) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.wrapper}>
        {props.icon ? (
          <RenderLoadingIconButton {...props}>
            {children}
          </RenderLoadingIconButton>
        ) : (
          <RenderLoadingRegularButton {...props}>
            {children}
          </RenderLoadingRegularButton>
        )}
      </Box>
    </Box>
  );
};

export default CustomLoadingButton;
