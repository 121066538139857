import {
  IAutopilotVersion,
  IBakusVersion,
  IBakusVersionWithCompleteDetails,
  IGuiNativeVersion,
  IGuiVersion,
  INetworkVersion,
  IVersionDetail,
  IWizardVersion,
} from "../../types/versions";

import { findByID } from "../array";

export const filterUnusedVersions =
  (versionDetailAssociatedToBakusVersions: IVersionDetail[]) =>
  (versionDetail: IVersionDetail) =>
    versionDetailAssociatedToBakusVersions.some(
      (bakusVersionDetail) => bakusVersionDetail.id === versionDetail.id,
    );

export const filterObsoleteVersions = (versionDetail: IVersionDetail) =>
  versionDetail.deprecated === false;

export function getVersionsDetailsFromBakusVersion(
  bakusVersion: IBakusVersion | null,
  autopilotVersionsList: IAutopilotVersion[],
  wizardVersionsList: IWizardVersion[],
  networkVersionsList: INetworkVersion[],
  guiNativeVersionsList: IGuiNativeVersion[],
  guiVersionsList: IGuiVersion[],
): IBakusVersionWithCompleteDetails["details"] {
  const autopilotVersion = findByID(
    bakusVersion?.details.autopilotVersionID,
    autopilotVersionsList,
  );

  const wizardVersion = findByID(
    bakusVersion?.details.wizardVersionID,
    wizardVersionsList,
  );

  const networkVersion = findByID(
    bakusVersion?.details.networkVersionID,
    networkVersionsList,
  );

  const guiNativeVersion = findByID(
    bakusVersion?.details.guiNativeVersionID,
    guiNativeVersionsList,
  );

  const guiVersion = findByID(
    bakusVersion?.details.guiVersionID,
    guiVersionsList,
  );

  return {
    autopilotVersion,
    wizardVersion,
    networkVersion,
    guiNativeVersion,
    guiVersion,
  };
}

export function getModuleAssociatedToBakusVersions(
  bakusVersionsList: IBakusVersion[],
  versionDetailList: IVersionDetail[],
  bakusVersionDetailFieldName: keyof IBakusVersion["details"],
) {
  const bakusVersionsWithVersionDetail = bakusVersionsList.filter(
    (bakusVersion) =>
      bakusVersion.details[bakusVersionDetailFieldName] !== null,
  );

  return versionDetailList.filter((versionDetail) =>
    bakusVersionsWithVersionDetail.some(
      (bakusVersion) =>
        bakusVersion.details[bakusVersionDetailFieldName] === versionDetail.id,
    ),
  );
}
