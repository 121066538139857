import { ATOMS } from "../../recoil/atom";
import { CustomConfirmButton } from "../../utils/Buttons";
import { ExitToAppSharp } from "@mui/icons-material";
import { fireAuth } from "../../config/firebase";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useSetAtom } from "jotai";
import { useTranslation } from "react-i18next";

const SignOutButton = () => {
  const { t } = useTranslation(["login"]);
  const navigate = useNavigate();

  const setOpen = useSetAtom(ATOMS.UI_DRAWER_OPEN);

  const handleSignOut = async () => {
    await signOut(fireAuth);
    window.localStorage.clear();
    setOpen(false);
    navigate("/login", { replace: true }); // Avoid getting some error when disconnecting
  };

  return (
    <CustomConfirmButton
      onClick={handleSignOut}
      title={t("login:sign_out_confirm_message")}
      id="SignInButton"
      color="inherit"
      data-cy="disconnect-btn"
      size="large"
      icon={<ExitToAppSharp />}
    />
  );
};

export default SignOutButton;
