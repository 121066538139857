import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Slide,
  SlideProps,
  Tooltip,
  TooltipProps,
} from "@mui/material";
import CustomButton, { ICustomButtonProps } from "./CustomButton";
import CustomLoadingButton, {
  ILoadingButtonProps,
  ILoadingButtonPropsExtended,
} from "./CustomLoadingButton";
import { forwardRef, useState } from "react";
import { hasColor, isButtonFeedback } from "./functions";

import { ATOMS } from "../../recoil/atom";
import { UI_THEMES } from "../../constants/theme";
import { isDefined } from "../object";
import { useAtomValue } from "jotai";
import { useTranslation } from "react-i18next";

const Transition = forwardRef<unknown, SlideProps>((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IConfirmButtonProps {
  dialogMaxWidth?: DialogProps["maxWidth"];
  title?: string;
  contentText?: string;
  confirmMsg?: string;
  cancelMsg?: string;
  tooltipTitle?: string;
  tooltipPlacement?: TooltipProps["placement"];
  loading?: boolean;
}

type IConfirmButtonPropsExtended =
  | (IConfirmButtonProps & ICustomButtonProps)
  | (IConfirmButtonProps & ICustomButtonProps & ILoadingButtonProps);

/**
 * Custom button component with confirm dialog
 * @example
 *   // Button Props
 * - color="primary"
 * - variant="contained"
 * - size="large"
 * - onClick={function}
 * - disabled={boolean}
 *   // Loading Button Props
 * - loading={boolean}
 * - loadingType="circular"
 *   // Dialog Props
 * - dialogMaxWidth="sm"
 * - title="Dialog title"
 * - contentText="Dialog content text"
 * - cancelMsg="Dialog action cancel message"
 * - confirmMsg="Dialog action confirm message"
 *   // Tooltip Props
 * - tooltipTitle="Tooltip title"
 * - tooltipPlacement="Tooltip placement"
 *   // Icon Props
 * - icon={<Icon fontSize="inherit" />}
 *    | startIcon={<Icon />}
 *    | endIcon={<Icon className={classes.rightIcon} />}
 *   // Other
 * - ...ButtonProps (@mui/material/Button)
 */
const CustomConfirmButton = ({
  onClick,
  dialogMaxWidth,
  title,
  contentText,
  confirmMsg,
  cancelMsg,
  tooltipTitle,
  tooltipPlacement,
  loading,
  ...props
}: IConfirmButtonPropsExtended) => {
  const { t } = useTranslation();

  const uiTheme = useAtomValue(ATOMS.UI_THEME);

  const [openDialog, setOpenDialog] = useState(false);

  const handleDialog = (isResponseAffirmative: boolean) => {
    if (isResponseAffirmative) {
      onClick();
    }
    setOpenDialog(false);
  };

  const hasValidColor = hasColor(props.color);
  const hasButtonFeedbackColor = isButtonFeedback(props.color);

  const isUiThemeInDarkMode = uiTheme === UI_THEMES.DARK;

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={dialogMaxWidth ?? "xs"}
        open={openDialog}
        onClose={() => handleDialog(false)}
        TransitionComponent={Transition}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
        keepMounted
      >
        {/* Dialog - Title */}
        <DialogTitle id="confirm-dialog-title">
          {title ? title : t("confirm_action_message")}
        </DialogTitle>

        {/* Dialog - Content */}
        {contentText && (
          <DialogContent id="confirm-dialog-description">
            {contentText}
          </DialogContent>
        )}

        {/* Dialog - Actions */}
        <DialogActions>
          <Button
            data-cy="dialog-confirm-button-cancel"
            id="dialog-confirm-button-cancel"
            variant={
              hasButtonFeedbackColor
                ? "contained"
                : isUiThemeInDarkMode
                  ? "outlined"
                  : "text"
            }
            onClick={() => handleDialog(false)}
            autoFocus
          >
            {cancelMsg
              ? cancelMsg
              : hasButtonFeedbackColor
                ? t("cancel")
                : t("no")}
          </Button>
          <Button
            data-cy="dialog-confirm-button-confirm"
            id="dialog-confirm-button-confirm"
            variant="contained"
            color={
              hasValidColor ? (props.color as ButtonProps["color"]) : "primary"
            }
            onClick={() => handleDialog(true)}
          >
            {confirmMsg
              ? confirmMsg
              : hasButtonFeedbackColor
                ? t("continue")
                : t("yes")}
          </Button>
        </DialogActions>
      </Dialog>

      <Tooltip
        title={tooltipTitle ? tooltipTitle : ""}
        placement={tooltipPlacement ? tooltipPlacement : "bottom"}
        arrow
      >
        <div>
          {/* Button */}
          {isDefined(loading) && loading ? (
            <CustomLoadingButton
              {...(props as ILoadingButtonPropsExtended)}
              loading={loading}
            >
              {props.children}
            </CustomLoadingButton>
          ) : (
            <CustomButton onClick={() => setOpenDialog(true)} {...props}>
              {props.children}
            </CustomButton>
          )}
        </div>
      </Tooltip>
    </>
  );
};

export default CustomConfirmButton;
