import {
  IStatusCodeEntry,
  IStatusCodeFromFirebase,
  IStatusCodeMessageWithResolution,
  IStatusCodeMessageWithResolutionLegacy,
} from "../../types/statusCode";
import { collection, onSnapshot, query } from "firebase/firestore";
import { useAtomValue, useSetAtom } from "jotai";
import { useEffect, useState } from "react";

import { ATOMS } from "../../recoil/atom";
import { ILanguage } from "../../types/languages";
import LANGUAGES from "../../constants/languages";
import { createSnackBarContent } from "../global/useSnackBar";
import { fireDatabase } from "../../config/firebase";
import { isValidNonEmptyArray } from "../../utils/array";
import { makeEmptyStatusCodeTranslations } from "../../utils/translations";
import { useTranslation } from "react-i18next";

const useStatusCodes = () => {
  const { t } = useTranslation(["snackbar"]);

  const popSnackbar = useSetAtom(ATOMS.SNACKBAR);
  const pageVisible = useAtomValue(ATOMS.PAGE_VISIBLE);

  const setStatusCodes = useSetAtom(ATOMS.STATUS_CODES);

  const [canLoadData, setCanLoadData] = useState(false);

  useEffect(() => {
    setCanLoadData(pageVisible);
  }, [pageVisible]);

  // StatusCodes list
  useEffect(() => {
    if (canLoadData) {
      // ref for the first page
      let firebaseRef = query(collection(fireDatabase, "status_codes"));

      const unsub = onSnapshot(
        firebaseRef,
        (snap) => {
          const dataStatusCodes: IStatusCodeEntry[] = [];
          // This logic help us to sort result and keep pagination working
          let newSnap = snap.docs;

          newSnap.sort((docA, docB) =>
            docA.id.localeCompare(docB.id, LANGUAGES.FRENCH.code, {
              numeric: true,
            }),
          );

          newSnap.forEach((doc) => {
            const {
              supervisor_comment: supervisorMsgList,
              user: userMsgList,
              ...statusCode
            } = doc.data() as IStatusCodeFromFirebase;

            const translations = makeEmptyStatusCodeTranslations();

            // Get supervisor messages data
            if (isValidNonEmptyArray(supervisorMsgList)) {
              supervisorMsgList.forEach(
                (supervisor: Partial<IStatusCodeMessageWithResolution>) => {
                  const current =
                    translations[supervisor?.code as ILanguage["code"]];

                  if (!current) {
                    return;
                  }

                  current.msgSupervisor = supervisor?.message ?? null;
                  current.supervisorResolution = supervisor?.resolution ?? null;
                },
              );
            }

            // Get user messages data
            if (isValidNonEmptyArray(userMsgList)) {
              userMsgList.forEach(
                (user: Partial<IStatusCodeMessageWithResolutionLegacy>) => {
                  const current = translations[user?.code as ILanguage["code"]];

                  if (!current) {
                    return;
                  }

                  current.msgUser = user?.message ?? null;
                  current.userResolution = user?.resolution ?? null;
                  current.userLockResolution = user?.lock_resolution ?? null;
                },
              );
            }

            const res: IStatusCodeEntry = {
              ...statusCode,
              translations,
            };

            dataStatusCodes.push(res);
          });

          setStatusCodes(dataStatusCodes);
        },
        (err) => {
          console.error(err);
          popSnackbar(
            createSnackBarContent({
              content: t("snackbar:request_status_codes_error"),
              severity: "error",
            }),
          );
          setStatusCodes([]);
        },
      );
      return () => unsub();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canLoadData]);
};

export default useStatusCodes;
