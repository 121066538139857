import { doc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";

import { ATOMS } from "../../recoil/atom";
import { createSnackBarContent } from "../global/useSnackBar";
import { fireDatabase } from "../../config/firebase";
import { needRequestCollections } from "../../constants/access";
import useNeedRequestVisibilityHelper from "../data/useNeedRequestVisibilityHelper";
import { useSetAtom } from "jotai";
import { useTranslation } from "react-i18next";

const collectionRequired = [needRequestCollections.COUNTERS];

const useCounter = () => {
  const { t } = useTranslation(["snackbar"]);

  const popSnackbar = useSetAtom(ATOMS.SNACKBAR);
  const setCounterAccess = useSetAtom(ATOMS.COUNTER_ACCESS);
  const setCounterHardwareLocations = useSetAtom(
    ATOMS.COUNTER_HARDWARE_LOCATION,
  );
  const setCounterStatusCodes = useSetAtom(ATOMS.COUNTER_STATUS_CODES);

  const [unSubscribe, setUnSubscribe] = useState<(() => void) | null>(null);

  const canLoadData = useNeedRequestVisibilityHelper(() => {
    unSubscribe && unSubscribe();
  }, collectionRequired);

  // Counters data
  useEffect(() => {
    if (canLoadData) {
      const unsub = onSnapshot(
        doc(fireDatabase, "counters/data"),
        (doc) => {
          const accessData = doc.data()?.access;
          const statusCodesData = doc.data()?.status_codes;
          const hardwareLocationsData = doc.data()?.hardware_locations;

          setCounterAccess(accessData);
          setCounterStatusCodes(statusCodesData);
          setCounterHardwareLocations(hardwareLocationsData);
        },
        (err) => {
          console.error(err);
          popSnackbar(
            createSnackBarContent({
              content: t("snackbar:request_counter_error"),
              severity: "error",
            }),
          );
          setCounterAccess({ disable: 0, enable: 0, roles: [] });
          setCounterStatusCodes({ number: 0 });
          setCounterHardwareLocations({ number: 0 });
        },
      );
      setUnSubscribe(() => unsub);
      return () => unsub();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canLoadData]);

  return null;
};

export default useCounter;
