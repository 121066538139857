import { Navigate, useLocation } from "react-router-dom";

import { ATOMS } from "../recoil/atom";
import FixedSpinner from "../components/Global/FixedSpinner";
import { isDefined } from "../utils/object";
import { useAtomValue } from "jotai";

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();

  const waitInit = useAtomValue(ATOMS.UI_WAITING_FIREBASE_INIT);

  const currentUser = useAtomValue(ATOMS.USER_CURRENT);

  if (waitInit) {
    return <FixedSpinner />;
  }

  if (!isDefined(currentUser)) {
    console.log("User is undefined");

    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default RequireAuth;
