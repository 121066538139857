import { collection, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";

import { ATOMS } from "../../recoil/atom";
import { IGuiNativeVersion } from "../../types/versions";
import { createSnackBarContent } from "../global/useSnackBar";
import { fireDatabase } from "../../config/firebase";
import { needRequestCollections } from "../../constants/access";
import { sortByCreationDate } from "../../utils/versions";
import useNeedRequestVisibilityHelper from "../data/useNeedRequestVisibilityHelper";
import { useSetAtom } from "jotai";
import { useTranslation } from "react-i18next";

const collectionRequired = [needRequestCollections.VERSIONS];

const useGuiNativeVersionsList = () => {
  const { t } = useTranslation(["snackbar"]);

  const popSnackbar = useSetAtom(ATOMS.SNACKBAR);

  const setGuiNativeVersionsList = useSetAtom(ATOMS.GUI_NATIVE_VERSIONS);

  const [unSubscribe, setUnSubscribe] = useState<(() => void) | null>(null);

  const canLoadData = useNeedRequestVisibilityHelper(() => {
    unSubscribe && unSubscribe();
  }, collectionRequired);

  // Versions types list
  useEffect(() => {
    if (canLoadData) {
      const unsub = onSnapshot(
        collection(fireDatabase, "versions", "details", "gui_native_versions"),
        (snap) => {
          const dataGuiNativeVersions: IGuiNativeVersion[] = [];
          snap.docs.forEach((doc) => {
            const data = doc.data();
            if (data) {
              dataGuiNativeVersions.push(data as IGuiNativeVersion);
            }
          });
          setGuiNativeVersionsList(
            dataGuiNativeVersions.sort(sortByCreationDate("desc")),
          );
        },
        (err) => {
          console.error(err);
          popSnackbar(
            createSnackBarContent({
              content: t("snackbar:request_gui_native_versions_error"),
              severity: "error",
            }),
          );
          setGuiNativeVersionsList([]);
        },
      );

      setUnSubscribe(() => unsub);
      return () => unsub();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canLoadData]);

  return null;
};

export default useGuiNativeVersionsList;
