import { useLayoutEffect, useState } from "react";

import useDebounce from "../func/useDebounce";

function getWindowDimensions() {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
}

export default function useWindowDimensions(debounceDelay = 500) {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  const handleResize = useDebounce(
    () => setWindowDimensions(getWindowDimensions),
    debounceDelay,
  );

  /**
   * We use useLayoutEffect instead of a regular useEffect because we want to execute the following code
   * right after the React component's render lifecycle (and before any other useEffect)
   */
  useLayoutEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return windowDimensions;
}
