import { Stack, Tooltip, Typography } from "@mui/material";

import { ATOMS } from "../../recoil/atom";
import { Box } from "@mui/system";
import { ROLES } from "../../constants/access/roles";
import { UserNameContainer } from "./UserName.styled";
import { areValidNonEmptyArrays } from "../../utils/array";
import { isDefined } from "../../utils/object";
import { isValidNotEmptyString } from "../../utils/string";
import { nameCleaner } from "../../utils/robot";
import { useAtomValue } from "jotai";

const UserName = () => {
  const currentUser = useAtomValue(ATOMS.USER_CURRENT);

  const userRoles = useAtomValue(ATOMS.USER_CURRENT_ROLES);

  const userHighestRole = useAtomValue(ATOMS.USER_HIGHEST_ROLE);

  const rolesAvailable: string[] = Object.values(ROLES);

  if (!isDefined(currentUser)) {
    return null;
  }

  const tooltipTitle = areValidNonEmptyArrays([userRoles, rolesAvailable]) ? (
    <Box minWidth={100} py={0.25}>
      {userRoles
        .sort(
          (r1, r2) =>
            (rolesAvailable.findIndex((r) => r1 === r) -
              rolesAvailable.findIndex((r) => r2 === r)) *
            -1,
        )
        .map((role) => (
          <Typography key={role} textTransform="capitalize">
            ∙ {role}
          </Typography>
        ))}
    </Box>
  ) : (
    "" // Zero-length tooltip titles string are never displayed
  );

  return (
    <Tooltip title={tooltipTitle} arrow>
      <UserNameContainer>
        <Stack direction="column" spacing={-0.5}>
          <Typography fontWeight="bold">
            {nameCleaner(currentUser.email)}
          </Typography>
          {isValidNotEmptyString(userHighestRole) ? (
            <Typography variant="caption">{userHighestRole}</Typography>
          ) : null}
        </Stack>
      </UserNameContainer>
    </Tooltip>
  );
};

export default UserName;
