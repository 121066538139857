import { IndexedStorage } from "../utils/Storage/type";
import { atom } from "jotai";
import { deepEqual } from "../utils/object";

export const storeKeys = {
  PARCELS_LIST: "parcels_list",
  POI_LIST: "poi_list",
  POI_TYPES: "poi_types",
  ROBOT_LIST: "robot_list",
  STATUS_CODES: "status_code",
  USER_CURRENT_USER: "user_current_user",
  USER_CURRENT_USER_ROLES: "user_current_user_roles",
  USER_CURRENT_USER_ACCESS: "user_current_user_access",
  USER_HIGHEST_ROLE: "user_highest_role",
  UI_THEME: "ui_theme",
  UI_DRAWER_OPEN: "ui_drawer_open",
  VERSIONS_TYPES: "versions_types",
  VERSIONS_CANALS: "versions_canals",
  BAKUS_VERSIONS: "bakus_versions",
  AUTOPILOT_VERSIONS: "autopilot_versions",
  WIZARD_VERSIONS: "wizard_versions",
  NETWORK_VERSIONS: "network_versions",
  GUI_NATIVE_VERSIONS: "gui_native_versions",
  GUI_VERSIONS: "gui_versions",
  DISTRIBUTOR_LIST: "distributor_list",
  UI_OVERVIEW_SHOW_BATTERY: "UI_OVERVIEW_SHOW_BATTERY",
};

const replacer = (_: unknown, value: unknown) => {
  if (value instanceof Map) {
    return {
      dataType: "Map",
      value: Array.from(value.entries()),
    };
  }

  return value;
};

const reviver = (_: unknown, value: any) => {
  if (typeof value === "object" && value !== null) {
    if (value.dataType === "Map") {
      return new Map(value.value);
    }
  }

  return value;
};

export const extractFromStorage = <T>(key: string, defaultValue: T) => {
  const value = window.localStorage.getItem(key);
  if (value === null || value === undefined) {
    return defaultValue;
  }
  try {
    return JSON.parse(value, reviver) as T;
  } catch (error) {
    console.log("Unable to read data");
  }
  return defaultValue;
};

export const putToStorage = (key: string, value: any) => {
  window.localStorage.setItem(key, JSON.stringify(value, replacer));
};

export const cleanFromStorage = (key: string) => {
  window.localStorage.removeItem(key);
};

export const fleetAtomWithStorage = <T>(key: string, initialValue: T) => {
  const baseAtom = atom(extractFromStorage(key, initialValue));
  const derivedAtom = atom(
    (get) => get(baseAtom),
    (get, set, update: T) => {
      const prevValue = get(baseAtom);
      const nextValue =
        typeof update === "function" ? update(get(baseAtom)) : update;
      if (deepEqual(prevValue, nextValue)) {
        console.log(`Preventing save of ${key}`);
      } else {
        // console.log("Saving", key, nextValue);
        set(baseAtom, nextValue);
        putToStorage(key, nextValue);
      }
    },
  );
  return derivedAtom;
};

export const fleetAtomWithIndexedStorage = <T>(
  key: string,
  storage: IndexedStorage<T>,
  initialValue: T[],
) => {
  cleanFromStorage(key); // Migration to indexedDB

  const baseAtom = atom(storage.getAll(initialValue).then((res) => res));
  const derivedAtom = atom(
    (get) => get(baseAtom),
    async (get, set, update: T[]) => {
      const prevValue = await get(baseAtom);
      const nextValue = update;
      if (deepEqual(prevValue, nextValue)) {
        console.log(`Preventing save of ${key}`);
      } else {
        // console.log("Saving", key, nextValue);
        set(baseAtom, Promise.resolve(nextValue));
        // putToStorage(key, nextValue);
        storage.setAll(nextValue).then(() => {
          console.log("Parcels saved");
        });

        // // At this point, the atom value has been updated to the read value, it's time to filter data, and save the filtered.
        // const baseFilterDate = DateTime.now().minus({ month: 6 }).toSeconds();
        // const filteredValue = nextValue.filter(
        //   (parcel: IParcelMetaWithId) => parcel.update_date >= baseFilterDate
        // );
        // putToStorage(key, filteredValue);
      }
    },
  );
  return derivedAtom;
};
