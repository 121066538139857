import {
  IAccessEntryForCollection,
  IDisabledRole,
  IRoleEntry,
} from "../types/access";
import {
  IAutopilotVersion,
  IBakusVersion,
  IGuiNativeVersion,
  IGuiVersion,
  INetworkVersion,
  IVersionCanal,
  IVersionType,
  IWizardVersion,
} from "../types/versions";
import { ICounterAccess, ISimpleCounter } from "../types/counter";
import { IPoi, IPoiTypes } from "../types/poi";
import { IToolConfig, IToolModel } from "../types/tool";
import {
  fleetAtomWithIndexedStorage,
  fleetAtomWithStorage,
  storeKeys,
} from "./utils";

import { AtLeast } from "../utils/type";
import { IBasicRobot } from "../types/robot";
import { IDistributor } from "../types/distributor";
import { IFirebaseUser } from "../types/firebase";
import { INeedRequestCollectionSet } from "../types/utils";
import { IParcelMetaWithId } from "../types/parcel";
import { ISimpleUser } from "../types/user";
import { ISnackbarContent } from "../types/snackbar";
import { IStatusCodeEntry } from "../types/statusCode";
import { LatLngTuple } from "leaflet";
import { TFunction } from "i18next";
import { UI_THEMES } from "../constants/theme";
import { atom } from "jotai";
import { atomWithReset } from "jotai/utils";
import { deepEqual } from "../utils/object";
import { needRequestCollections } from "../constants/access";
import { parcelStorage } from "../utils/Storage/Parcel";
import { sortUsersList } from "../utils/user";
import without from "lodash/without";

const snackBarInitialState: AtLeast<ISnackbarContent, "content"> = {
  content: "",
  duration: 0,
  position: "bottom-right",
  severity: "info",
};

const defaultCounterAccess: ICounterAccess = {
  disable: 0,
  enable: 0,
  roles: [],
};

const defaultSimpleCounter: ISimpleCounter = { number: 0 };

export const APP_BAR_TITLE_DEFAULT_VALUE = "app_bar_title:common";

const needRequestAtom = atom<needRequestCollections[]>([]);
const userListAtom = atom<ISimpleUser[]>([]);

export const ATOMS = {
  UI_THEME: fleetAtomWithStorage(storeKeys.UI_THEME, UI_THEMES.LIGHT),
  UI_DRAWER_OPEN: fleetAtomWithStorage(storeKeys.UI_DRAWER_OPEN, false),
  UI_APP_BAR_CONTENT: atomWithReset<
    Parameters<TFunction<"app_bar_title"[], undefined>>[0] | string
  >(APP_BAR_TITLE_DEFAULT_VALUE),
  UI_WAITING_FIREBASE_INIT: atom(true),
  UI_ACTIVE_ROBOT_MAP_FLY_TO_POS: atomWithReset<LatLngTuple | null>(null),
  UI_OVERVIEW_SHOW_BATTERY: fleetAtomWithStorage(
    storeKeys.UI_OVERVIEW_SHOW_BATTERY,
    false,
  ),
  PAGE_VISIBLE: atom(false),
  SNACKBAR: atom<
    | AtLeast<ISnackbarContent, "content">
    | AtLeast<ISnackbarContent, "content">[]
  >(snackBarInitialState),
  NEED_REQUEST: atom(
    (get) => get(needRequestAtom),
    (get, set, update: INeedRequestCollectionSet) => {
      const nv = update;
      const isAdd = nv.add;
      const needs = nv.needs;
      const prevValue = get(needRequestAtom);
      let futureValue: needRequestCollections[] = [];
      if (isAdd) {
        futureValue = [...new Set(prevValue.concat(needs))];
      } else {
        futureValue = without(prevValue, ...needs);
      }
      if (!deepEqual(futureValue, prevValue)) {
        set(needRequestAtom, futureValue);
      }
    },
  ),
  USER_CURRENT_ACCESS: fleetAtomWithStorage<string[]>(
    storeKeys.USER_CURRENT_USER_ACCESS,
    [],
  ),
  USER_CURRENT: fleetAtomWithStorage<IFirebaseUser | null>(
    storeKeys.USER_CURRENT_USER,
    null,
  ),
  USER_CURRENT_ROLES: fleetAtomWithStorage<string[]>(
    storeKeys.USER_CURRENT_USER_ROLES,
    [],
  ),
  USER_HIGHEST_ROLE: fleetAtomWithStorage<string | null>(
    storeKeys.USER_HIGHEST_ROLE,
    null,
  ),
  USER_LIST: atom(
    (get) => get(userListAtom),
    (_, set, users: ISimpleUser[]) => set(userListAtom, users),
  ),
  USER_DISABLED_LIST: atom<ISimpleUser[]>([]),
  USER_DEV_LIST: atom<ISimpleUser[]>((get) =>
    get(userListAtom)
      .filter((u) => u.roles.includes("dev"))
      .sort(sortUsersList),
  ),
  ROBOT_DISABLED_LIST: atom<IBasicRobot[]>([]),
  ROBOT_LIST: fleetAtomWithStorage<IBasicRobot[]>(storeKeys.ROBOT_LIST, []),
  PARCELS_LIST: fleetAtomWithIndexedStorage<IParcelMetaWithId>(
    storeKeys.PARCELS_LIST,
    parcelStorage,
    [],
  ),
  STATUS_CODES: fleetAtomWithStorage<IStatusCodeEntry[]>(
    storeKeys.STATUS_CODES,
    [],
  ),
  POI_LIST: fleetAtomWithStorage<IPoi[]>(storeKeys.POI_LIST, []),
  POI_TYPES: fleetAtomWithStorage<IPoiTypes[]>(storeKeys.POI_TYPES, []),
  ACCESS_DISABLED_LIST: atom<IDisabledRole[]>([]),
  ACCESS_LIST: atom<IRoleEntry[]>([]),
  ACCESS_ROLES: atom<IAccessEntryForCollection[]>([]),
  COUNTER_ACCESS: atom(defaultCounterAccess),
  COUNTER_HARDWARE_LOCATION: atom(defaultSimpleCounter),
  COUNTER_STATUS_CODES: atom(defaultSimpleCounter),
  TOOL_CONFIG_LIST: atom<IToolConfig[]>([]),
  TOOL_MODEL_LIST: atom<IToolModel[]>([]),
  VERSIONS_TYPES: fleetAtomWithStorage<IVersionType[]>(
    storeKeys.VERSIONS_TYPES,
    [],
  ),
  VERSIONS_CANALS: fleetAtomWithStorage<IVersionCanal[]>(
    storeKeys.VERSIONS_CANALS,
    [],
  ),
  BAKUS_VERSIONS: fleetAtomWithStorage<IBakusVersion[]>(
    storeKeys.BAKUS_VERSIONS,
    [],
  ),
  AUTOPILOT_VERSIONS: fleetAtomWithStorage<IAutopilotVersion[]>(
    storeKeys.AUTOPILOT_VERSIONS,
    [],
  ),
  WIZARD_VERSIONS: fleetAtomWithStorage<IWizardVersion[]>(
    storeKeys.WIZARD_VERSIONS,
    [],
  ),
  NETWORK_VERSIONS: fleetAtomWithStorage<INetworkVersion[]>(
    storeKeys.NETWORK_VERSIONS,
    [],
  ),
  GUI_NATIVE_VERSIONS: fleetAtomWithStorage<IGuiNativeVersion[]>(
    storeKeys.GUI_NATIVE_VERSIONS,
    [],
  ),
  GUI_VERSIONS: fleetAtomWithStorage<IGuiVersion[]>(storeKeys.GUI_VERSIONS, []),

  DISTRIBUTOR_LIST: fleetAtomWithStorage<IDistributor[]>(
    storeKeys.DISTRIBUTOR_LIST,
    [],
  ),
};

export const DERIVED_ATOMS = {
  FACTORY_VERSION_TYPE_ID: atom(
    (get) =>
      get(ATOMS.VERSIONS_TYPES).find(
        ({ name }) => name.toUpperCase() === "PRODUCTION",
      )?.id,
  ),
};
