import { ThemeOptions } from "@mui/material";

export const baseStyle: ThemeOptions = {
  components: {
    MuiAvatar: {
      styleOverrides: {
        rounded: {
          borderRadius: 4,
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          marginTop: 0,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.variant === "head" && {
            backgroundColor:
              theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
          }),
        }),
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderBottomRightRadius: theme.shape.borderRadius,
          borderBottomLeftRadius: theme.shape.borderRadius,
          backgroundColor:
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
        }),
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
  palette: {
    primary: {
      dark: "rgb(6, 60, 136)",
      light: "rgb(58, 120, 207)",
      main: "#0957c3",
    },
    secondary: {
      dark: "rgb(38, 40, 37)",
      light: "rgb(95, 97, 94)",
      main: "#373a36",
    },
  },
  typography: {
    fontFamily: '"Montserrat", sans-serif',
  },
};
