import { ROLES } from "./access/roles";

export const PAGE_COLLECTION_REQUIRED = {
  // Data viz
  OVERVIEW: ["users", "robots", "parcels", "map", "counters"],
  ERROR_REPORT: ["robots", "analytics"],
  ACTIVITY: ["robots", "analytics"],

  // Management
  USERS_MANAGEMENT: ["users", "robots", "parcels", "access"],
  ROBOTS_MANAGEMENT: ["robots"],
  PARCELS_MANAGEMENT: ["parcels"],
  MAP_MANAGEMENT: ["map"],
  ACCESS_MANAGEMENT: ["access", "users"],
  STATUS_CODES_MANAGEMENT: ["status_codes"],
  LOGS_MANAGEMENT: ["logs", "users"],
  VERSIONS_MANAGEMENT: ["versions", "robots"],
  DISTRIBUTOR_MANAGEMENT: ["distributor"],

  // Sav
  SAV_DASHBOARD: ["robots"],
  SAV_HARDWARE_VERSION: ["robots"],
  SAV_FULLY_MANAGER: ["users"],

  // Tools
  TOOLS_CONFIG: ["tools_config", "robots"],
  TOOLS_MODELS: ["tools_model"],
  PARCEL_EXTREMITY_GROUP: [
    "parcels",
    "parcel_extremity_group",
    "tools_config",
    "robots",
  ],

  // Admin
  WIFI_CONFIG: ["robots"],

  // Mobile app
  GUI: ["gui"],
} as const;

export const PAGE_FALLBACK = "/sav/dashboard";

export const MENU_COLLECTION_REQUIRED = {
  DATA_VISUALIZATION: [
    ...new Set([
      ...PAGE_COLLECTION_REQUIRED.OVERVIEW,
      ...PAGE_COLLECTION_REQUIRED.ERROR_REPORT,
      ...PAGE_COLLECTION_REQUIRED.ACTIVITY,
    ]),
  ],
  MANAGEMENT: [
    ...new Set([
      ...PAGE_COLLECTION_REQUIRED.USERS_MANAGEMENT,
      ...PAGE_COLLECTION_REQUIRED.ROBOTS_MANAGEMENT,
      ...PAGE_COLLECTION_REQUIRED.PARCELS_MANAGEMENT,
      ...PAGE_COLLECTION_REQUIRED.MAP_MANAGEMENT,
      ...PAGE_COLLECTION_REQUIRED.ACCESS_MANAGEMENT,
      ...PAGE_COLLECTION_REQUIRED.STATUS_CODES_MANAGEMENT,
      ...PAGE_COLLECTION_REQUIRED.LOGS_MANAGEMENT,
      ...PAGE_COLLECTION_REQUIRED.VERSIONS_MANAGEMENT,
      ...PAGE_COLLECTION_REQUIRED.DISTRIBUTOR_MANAGEMENT,
    ]),
  ],
  SAV: [
    ...new Set([
      ...PAGE_COLLECTION_REQUIRED.SAV_DASHBOARD,
      ...PAGE_COLLECTION_REQUIRED.SAV_HARDWARE_VERSION,
      ...PAGE_COLLECTION_REQUIRED.SAV_FULLY_MANAGER,
    ]),
  ],
  TOOLS: [
    ...new Set([
      ...PAGE_COLLECTION_REQUIRED.TOOLS_CONFIG,
      ...PAGE_COLLECTION_REQUIRED.TOOLS_MODELS,
      ...PAGE_COLLECTION_REQUIRED.PARCEL_EXTREMITY_GROUP,
    ]),
  ],
  MOBILE_APP: [...new Set([...PAGE_COLLECTION_REQUIRED.GUI])],
} as const;

export const MENU_ROLE_REQUIRED = {
  ADMIN: [ROLES.ADMIN, ROLES.MASTER_ADMIN],
} as const;

export const PAGE_ROLE_REQUIRED = {
  ACCESS_MANAGEMENT: [ROLES.ADMIN, ROLES.MASTER_ADMIN],
  DATABASE: [ROLES.ADMIN, ROLES.MASTER_ADMIN],
  WIFI_CONFIG: [ROLES.ADMIN, ROLES.MASTER_ADMIN, ROLES.SUPERVISOR],
  VERSIONS: [
    ROLES.DATA_VIEWER,
    ROLES.DEV,
    ROLES.SUPERVISOR,
    ROLES.ADMIN,
    ROLES.MASTER_ADMIN,
  ],
  DISTRIBUTOR: [ROLES.ADMIN, ROLES.MASTER_ADMIN, ROLES.DISTRIBUTOR_MANAGER],
} as const;

export enum needRequestCollections {
  ACCESS = "access",
  ACCESS_ROLES = "accessRoles",
  COUNTERS = "counters",
  DISTRIBUTORS = "distributors",
  OVERVIEW = "overview",
  PARCELS = "parcels",
  POIS = "pois",
  ROBOTS = "robots",
  TOOLS_CONFIG = "toolsConfig",
  TOOLS_MODEL = "toolsModel",
  USERS = "users",
  VERSIONS = "versions",
}
