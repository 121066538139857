import { ATOMS } from "../../recoil/atom";
import { useAtom } from "jotai";

const useNeedRequest = () => {
  const [needRequest, setNeedRequest] = useAtom(ATOMS.NEED_REQUEST);

  return { needRequest, setNeedRequest };
};

export default useNeedRequest;
