import { Divider, styled } from "@mui/material";

import PAGE from "../../constants/page";
import PageContentWrapper from "../../utils/Wrappers/PageContentWrapper";

export const PageRootContainer = PageContentWrapper;

export const PageDivider = styled(Divider, {
  name: "PageDivider",
})({
  margin: `${PAGE.CONTENT.SPACING}px auto`,
  alignSelf: "center",
  width: "97%",
  borderStyle: "dashed",
});

export const FixedPageCenter = styled("div", {
  name: "FixedPageCenter",
})`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
