import { IconButton, Menu, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";

import { Settings } from "luxon";
import { TranslateSharp as TranslateSharpIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const LocalesBtn = ({
  color,
}: {
  color:
    | "inherit"
    | "default"
    | "error"
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | undefined;
}) => {
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);
  const open = Boolean(anchorEl);

  const { i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    Settings.defaultLocale = i18n.language;
  }, [i18n.language]);

  const handleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="locales"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color={color}
        id="translateIconBtn"
        size="large"
      >
        <TranslateSharpIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        keepMounted
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        open={open}
        onClose={handleClose}
      >
        {Object.keys(i18n.options.resources || []).map((language) => {
          if (i18n.options.resources === undefined) return null; // This is only for assertion
          const tLang = i18n.options.resources[language];
          return (
            <MenuItem
              key={language}
              id={language}
              onClick={() => {
                changeLanguage(language);
                handleClose();
              }}
              selected={
                language === i18n.language ||
                language === i18n.language.slice(0, 2)
              }
            >
              {/* @ts-ignore */}
              {tLang.common.name}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default LocalesBtn;
