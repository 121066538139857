import {
  IAutopilotVersion,
  IBakusVersion,
  IBakusVersionWithCompleteDetails,
  IGuiNativeVersion,
  IGuiVersion,
  INetworkVersion,
  IVersionCanal,
  IWizardVersion,
} from "../../types/versions";

import { areDefined } from "../object";
import { findByID } from "../array";
import { getVersionsDetailsFromBakusVersion } from "./versionsDetails";
import { isValidNotEmptyString } from "../string";
import { sortByVersion } from "../versions";

export const filterUnusedBakusVersions =
  (bakusVersionAssociatedToCanals: IBakusVersion[]) =>
  (bakusVersion: IBakusVersion) =>
    bakusVersionAssociatedToCanals.some(
      (canalBakusVersion) => canalBakusVersion.id === bakusVersion.id,
    );

export const filterIncompleteBakusVersions = (bakusVersion: IBakusVersion) => {
  return checkBakusVersion(bakusVersion);
};

export const checkBakusVersion = (bakusVersion: IBakusVersion | null) => {
  return areDefined([
    bakusVersion,
    bakusVersion?.details.autopilotVersionID,
    bakusVersion?.details.wizardVersionID,
    bakusVersion?.details.networkVersionID,
    bakusVersion?.details.guiVersionID,
  ]);
};

export function getBakusVersionFromCanal(
  versionCanalID: string | null,
  versionsCanalsList: IVersionCanal[],
  bakusVersionsList: IBakusVersion[],
) {
  const canal = findByID(versionCanalID, versionsCanalsList);
  const bakusVersion = findByID(canal?.bakus_version_id, bakusVersionsList);
  return bakusVersion;
}

export const getBakusVersionFromDetails = (
  bakusVersionsList: IBakusVersion[],
  autopilotVersionID: string | null | undefined,
  wizardVersionID: string | null | undefined,
  networkVersionID: string | null | undefined,
  // guiVersionID: string | null | undefined
) => {
  if (
    !isValidNotEmptyString(autopilotVersionID) ||
    !isValidNotEmptyString(wizardVersionID) ||
    !isValidNotEmptyString(networkVersionID)
    // || !isValidNotEmptyString(guiVersionID)
  ) {
    return null;
  }

  const matchedBakusVersions =
    bakusVersionsList.filter(
      ({ details }) =>
        details.autopilotVersionID === autopilotVersionID &&
        details.wizardVersionID === wizardVersionID &&
        details.networkVersionID === networkVersionID,
      // && details.guiVersionID === guiVersionID
    ) ?? null;

  return matchedBakusVersions.sort(sortByVersion("desc"))[0] ?? null;
};

export function getBakusVersionsWithCompleteDetails(
  bakusVersion: IBakusVersion | null,
  autopilotVersionsList: IAutopilotVersion[],
  wizardVersionsList: IWizardVersion[],
  networkVersionsList: INetworkVersion[],
  guiNativeVersionsList: IGuiNativeVersion[],
  guiVersionsList: IGuiVersion[],
): IBakusVersionWithCompleteDetails | null {
  if (!bakusVersion) {
    return null;
  }

  const details = getVersionsDetailsFromBakusVersion(
    bakusVersion,
    autopilotVersionsList,
    wizardVersionsList,
    networkVersionsList,
    guiNativeVersionsList,
    guiVersionsList,
  );

  return { ...bakusVersion, details } as IBakusVersionWithCompleteDetails;
}

export function getBakusVersionsAssociatedToCanals(
  versionsCanalsList: IVersionCanal[],
  bakusVersionsList: IBakusVersion[],
) {
  const versionCanalsWithBakusVersion = versionsCanalsList.filter(
    ({ bakus_version_id }) => bakus_version_id !== null,
  );

  return bakusVersionsList.filter((bakusVersion) =>
    versionCanalsWithBakusVersion.some(
      ({ bakus_version_id }) => bakus_version_id === bakusVersion.id,
    ),
  );
}
