import useEnsureHasAccess, {
  TUseEnsureAccessProps,
} from "../hooks/access/useEnsureHasAccess";

import FixedSpinner from "../components/Global/FixedSpinner";
import NotFoundPage from "../pages/NotFoundPage";
import { ReactNode } from "react";
import { isDefined } from "../utils/object";

type EnsureAccessProps = {
  redirect?: boolean;
  children: ReactNode;
  FallbackComponent?: ReactNode;
};

const EnsureUserHasAccess = ({
  redirect = true,
  children,
  FallbackComponent,
  ...useEnsureHasAccessProps
}: EnsureAccessProps & TUseEnsureAccessProps): JSX.Element | null => {
  const hasAccess = useEnsureHasAccess(useEnsureHasAccessProps);

  if (!isDefined(hasAccess)) {
    return <FixedSpinner />;
  }

  if (!hasAccess) {
    if (isDefined(FallbackComponent)) {
      return <>{FallbackComponent}</>;
    }
    if (redirect) {
      return <NotFoundPage denied />;
    }
    return null;
  }

  return <>{children}</>;
};

export default EnsureUserHasAccess;
