import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { useAtom, useAtomValue, useSetAtom } from "jotai";

import { ATOMS } from "../../recoil/atom";
import { IFirebaseUserData } from "../../types/firebase";
import { createSnackBarContent } from "../global/useSnackBar";
import { fireDatabase } from "../../config/firebase";
import { getUserHighestRole } from "../../utils/access";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const useCurrentUserAccess = () => {
  const { t } = useTranslation(["snackbar"]);

  const popSnackbar = useSetAtom(ATOMS.SNACKBAR);
  const setUserAccess = useSetAtom(ATOMS.USER_CURRENT_ACCESS);
  const setUserHighestRole = useSetAtom(ATOMS.USER_HIGHEST_ROLE);
  const [userRoles, setUserRoles] = useAtom(ATOMS.USER_CURRENT_ROLES);

  const currentUser = useAtomValue(ATOMS.USER_CURRENT);

  useEffect(() => {
    if (currentUser) {
      const unsub = onSnapshot(
        doc(fireDatabase, "users", currentUser.uid),
        (snap) => {
          const userData = snap.data() as IFirebaseUserData;
          if (userData) {
            const userRoles = userData.access_roles;
            setUserRoles(userRoles);
            setUserHighestRole(getUserHighestRole(userRoles));
          }
        },
      );

      return () => unsub();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useEffect(() => {
    if (currentUser && userRoles) {
      const getCurrentUserAccessCollections = async () => {
        try {
          let roleQuery = query(
            collection(
              fireDatabase,
              "access",
              "role_based_access_data",
              "roles",
            ),
          );
          if (userRoles.length > 0) {
            roleQuery = query(
              roleQuery,
              where("read", "array-contains-any", userRoles),
            );
          }

          const availableCollections = await getDocs(roleQuery).then((snap) =>
            snap.docs.map((doc) => doc.id),
          );

          setUserAccess(availableCollections);
        } catch (error) {
          console.error(error);
          popSnackbar(
            createSnackBarContent({
              content: t("snackbar:function_access_collection_error"),
              severity: "error",
            }),
          );
        }
      };

      getCurrentUserAccessCollections();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, userRoles]);
};

export default useCurrentUserAccess;
