import { doc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";

import { ATOMS } from "../../recoil/atom";
import { IRoleBaseAccessData } from "../../types/access";
import { createSnackBarContent } from "../global/useSnackBar";
import { fireDatabase } from "../../config/firebase";
import { needRequestCollections } from "../../constants/access";
import useNeedRequestVisibilityHelper from "../data/useNeedRequestVisibilityHelper";
import { useSetAtom } from "jotai";
import { useTranslation } from "react-i18next";

const collectionRequired = [needRequestCollections.ACCESS];

const useAccess = () => {
  const { t } = useTranslation(["snackbar"]);

  const popSnackbar = useSetAtom(ATOMS.SNACKBAR);
  const setAccessList = useSetAtom(ATOMS.ACCESS_LIST);
  const setAccessDisabledList = useSetAtom(ATOMS.ACCESS_DISABLED_LIST);

  const [unSubscribe, setUnSubscribe] = useState<() => {} | null>();

  const canLoadData = useNeedRequestVisibilityHelper(() => {
    unSubscribe && unSubscribe();
  }, collectionRequired);

  // Access list
  useEffect(() => {
    if (canLoadData) {
      const unsub = onSnapshot(
        doc(fireDatabase, "access/role_based_access_data"),
        (doc) => {
          const docData = doc.data() as IRoleBaseAccessData;
          if (docData) {
            const orderedRoles = docData.leveledRoleList.sort(
              (a, b) => b.level - a.level,
            );
            setAccessList(orderedRoles);
            setAccessDisabledList(docData.disabledRoles);
          } else {
            setAccessList([]);
            setAccessDisabledList([]);
          }
        },
        (err) => {
          console.error(err);
          popSnackbar(
            createSnackBarContent({
              content: t("snackbar:request_access_error"),
              severity: "error",
            }),
          );
          setAccessList([]);
          setAccessDisabledList([]);
        },
      );
      setUnSubscribe(() => unsub);
      return () => unsub();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canLoadData]);

  return null;
};

export default useAccess;
