import { useEffect, useRef, useState } from "react";

import { ATOMS } from "../../recoil/atom";
import { isDefined } from "../../utils/object";
import { needRequestCollections } from "../../constants/access";
import { useAtomValue } from "jotai";
import useNeedRequest from "./useNeedRequest";

const useNeedRequestVisibilityHelper = (
  onPageHide: VoidFunction,
  needs: needRequestCollections[],
) => {
  const { needRequest, setNeedRequest } = useNeedRequest();
  const pageVisible = useAtomValue(ATOMS.PAGE_VISIBLE);
  const currentUser = useAtomValue(ATOMS.USER_CURRENT);

  const [canLoad, setCanLoad] = useState(false);
  const unSubscribed = useRef(false);

  useEffect(() => {
    if (!pageVisible) {
      onPageHide();
      unSubscribed.current = true;
      setNeedRequest({
        add: false,
        needs,
      });
    } else if (unSubscribed.current === true) {
      unSubscribed.current = false;
      setNeedRequest({
        add: true,
        needs,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageVisible, needs, onPageHide]);

  useEffect(() => {
    setCanLoad(
      isDefined(currentUser) &&
        needs.every((need) => needRequest.includes(need)),
    );
  }, [needRequest, needs, currentUser]);

  return canLoad;
};

export default useNeedRequestVisibilityHelper;
