import {
  IMayHaveIdName,
  IMayHaveIdVersion,
  ISimpleIdName,
  ISimpleIdVersion,
} from "../../types/versions";
import semver, {
  major,
  compare as semverCompare,
  rcompare as semverRcompare,
  valid as semverValid,
} from "semver";

import { Timestamp } from "firebase/firestore";
import { isValidNotEmptyString } from "../string";
import {
  getDateFromTimestamp,
  sortByDateAscending,
  sortByDateDescending,
  unknownTimeFormatToTimestamp,
} from "../date";

export const getCoercedSemanticVersion = (
  version: string | null | undefined,
): string | null => {
  const validVersion = semver.valid(version);
  if (!isValidNotEmptyString(version) || !validVersion) {
    return null;
  }
  const coercedVersion = semver.coerce(validVersion);
  return coercedVersion ? `${coercedVersion}` : null;
};

export const getVersionAutocompleteGroupOptions = (version: string) => {
  const coercedSemanticVersion = getCoercedSemanticVersion(version);

  const majorVersionNumber = isValidNotEmptyString(coercedSemanticVersion)
    ? major(coercedSemanticVersion as string)
    : null;

  const stringGroupLabel = "[a-z]";

  const groupLabel = majorVersionNumber
    ? `v${majorVersionNumber}`
    : stringGroupLabel;

  const groupIdx =
    groupLabel === stringGroupLabel
      ? 99999
      : majorVersionNumber
        ? majorVersionNumber
        : parseInt(coercedSemanticVersion?.replaceAll(".", "") ?? "-1");

  return { groupLabel, groupIdx };
};

export const sortByName = <T extends { name: string }>(a: T, b: T) => {
  return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
};

export const sortByCreationDate =
  (order: "asc" | "desc" = "asc") =>
  <T extends { creation_date: Timestamp }>(a: T, b: T) => {
    const dateA = getDateFromTimestamp(
      unknownTimeFormatToTimestamp(a.creation_date),
    );
    const dateB = getDateFromTimestamp(
      unknownTimeFormatToTimestamp(b.creation_date),
    );

    return order === "asc"
      ? sortByDateAscending(dateA, dateB)
      : sortByDateDescending(dateA, dateB);
  };

export const sortByVersion =
  <T extends { version: string | null | undefined }>(
    order: "asc" | "desc" = "asc",
  ) =>
  (a: T, b: T) => {
    return compareVersions(a.version, b.version, order);
  };

export const compareVersions = (
  a: string | null | undefined,
  b: string | null | undefined,
  order: "asc" | "desc" = "asc",
) => {
  const aValid = isValidNotEmptyString(semverValid(a));
  const bValid = isValidNotEmptyString(semverValid(b));

  if (aValid && bValid) {
    return order === "asc"
      ? semverCompare(a as string, b as string)
      : semverRcompare(a as string, b as string);
  }
  if (aValid && !bValid) {
    return order === "asc" ? 1 : -1;
  }
  if (!aValid && bValid) {
    return order === "asc" ? -1 : 1;
  }

  return 0;
};

export const findFromVersionString = <T extends { version: string }>(
  version: string | null | undefined,
  list: T[],
): T | null => {
  if (!isValidNotEmptyString(version)) {
    return null;
  }
  return (
    list.find((item) => {
      const a = getCoercedSemanticVersion(item.version);
      const b = getCoercedSemanticVersion(version);
      return a === b;
    }) ?? null
  );
};

/**
 * Compare function used to get visual compare elements (icon, color, ...)
 */
export function getCompareVersionsDiff(
  v1: string | null | undefined,
  v2: string | null | undefined,
): number | undefined {
  const c1 = getCoercedSemanticVersion(v1);
  const c2 = getCoercedSemanticVersion(v2);

  if (!c1 || !c2) {
    return undefined;
  }

  return semverCompare(c1 as string, c2 as string);
}

export const getDataIdNameFrom = <T extends IMayHaveIdName>(
  data: T | null,
): ISimpleIdName | null => {
  if (!!data && "id" in data && "name" in data) {
    const { id, name } = data;
    return { id, name } as ISimpleIdName;
  }

  return null;
};

export const getDataIdVersionFrom = <T extends IMayHaveIdVersion>(
  data: T | null,
): ISimpleIdVersion | null => {
  if (!!data && "id" in data && "version" in data) {
    const { id, version } = data;
    return { id, version } as ISimpleIdVersion;
  }

  return null;
};

// Semantic version - MAJOR

export const areMajorsDifferent = (
  versionA: string | undefined | null,
  versionB: string | undefined | null,
) => {
  const coercedVersionA = getCoercedSemanticVersion(versionA);
  const coercedVersionB = getCoercedSemanticVersion(versionB);

  if (!coercedVersionA || !coercedVersionB) {
    return false;
  }

  return semver.major(coercedVersionA) !== semver.major(coercedVersionB);
};

// Semantic version - MINOR

export const areMinorsDifferent = (
  versionA: string | undefined | null,
  versionB: string | undefined | null,
) => {
  const coercedVersionA = getCoercedSemanticVersion(versionA);
  const coercedVersionB = getCoercedSemanticVersion(versionB);

  if (!coercedVersionA || !coercedVersionB) {
    return false;
  }

  return semver.minor(coercedVersionA) !== semver.minor(coercedVersionB);
};

// Semantic version - PATCH

export const arePatchesDifferent = (
  versionA: string | undefined | null,
  versionB: string | undefined | null,
) => {
  const coercedVersionA = getCoercedSemanticVersion(versionA);
  const coercedVersionB = getCoercedSemanticVersion(versionB);

  if (!coercedVersionA || !coercedVersionB) {
    return false;
  }

  return semver.patch(coercedVersionA) !== semver.patch(coercedVersionB);
};

// Semantic version - MAJOR | MINOR

export const areMajorsOrMinorsDifferent = (
  versionA: string | undefined | null,
  versionB: string | undefined | null,
) => {
  return (
    areMajorsDifferent(versionA, versionB) ||
    areMinorsDifferent(versionA, versionB)
  );
};
