import {
  CssBaseline,
  StyledEngineProvider,
  Theme,
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material";
import { useAtomValue, useSetAtom } from "jotai";
import { useEffect, useState } from "react";

import { ATOMS } from "../../recoil/atom";
import { UI_THEMES } from "../../constants/theme";
import { baseStyle } from "../../config/theme";
import useInitHooks from "../../recoil/useInitHooks";

// inactivity time before unsubscribe on all data update listener
const inactivity_timeout = 10000;

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const AppScaffold = ({ children }: { children: JSX.Element }) => {
  useInitHooks();

  const [isPageVisible, setIsPageVisible] = useState(true);
  const [timeoutIndex, setTimeoutIndex] = useState<NodeJS.Timeout | null>(null);
  const setGlobalPageVisible = useSetAtom(ATOMS.PAGE_VISIBLE);

  const uiTheme = useAtomValue(ATOMS.UI_THEME);

  const appTheme = responsiveFontSizes(
    createTheme({
      ...baseStyle,
      palette: {
        ...baseStyle.palette,
        mode: uiTheme === UI_THEMES.LIGHT ? "light" : "dark",
      },
    }),
  );

  // set page visibility state to hidden after the inactivity timeout
  useEffect(() => {
    let timeOutId: NodeJS.Timeout;

    // if page is hidden
    if (!isPageVisible) {
      timeOutId = setTimeout(() => {
        setGlobalPageVisible(isPageVisible);
        setTimeoutIndex(null);
      }, inactivity_timeout);

      setTimeoutIndex(timeOutId);
    } else {
      if (timeoutIndex !== undefined && timeoutIndex !== null) {
        timeoutIndex && clearTimeout(timeoutIndex);
        setTimeoutIndex(null);
      } else {
        setGlobalPageVisible(isPageVisible);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPageVisible]);

  const handleVisibilityChange = () => {
    // https://developer.mozilla.org/en-US/docs/Web/API/Document/visibilityState is supported be all modern browsers
    setIsPageVisible(document.visibilityState === "visible");
  };

  useEffect(() => {
    // https://developer.mozilla.org/en-US/docs/Web/API/Document/visibilitychange_event supported by everyone
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () =>
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={appTheme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default AppScaffold;
