import LANGUAGES, { TLangValue } from "../constants/languages";
import { capitalize, isValidNotEmptyString } from "./string";

import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { IStatusCodeTranslations } from "../types/statusCode";
import { IconProps } from "@mui/material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { TFunction } from "i18next";
import { TriState } from "../types/robot";
import useCustomColors from "../hooks/global/useCustomColors";

export function getLangs(t: TFunction<["common"]>): TLangValue[] {
  return Object.values(LANGUAGES).map((lang) => ({
    label: capitalize(t(lang.label)) || "",
    code: lang.code,
    shortCode: lang.shortCode,
  }));
  // .sort((a, b) => (a.label > b.label ? 1 : -1));
}

export const makeEmptyStatusCodeTranslations = () => {
  const res = {} as IStatusCodeTranslations;

  Object.values(LANGUAGES).forEach(
    (language) =>
      (res[language.code] = {
        language,
        msgSupervisor: "",
        supervisorResolution: "",
        msgUser: "",
        userResolution: "",
        userLockResolution: "", // ! --> Legacy
      }),
  );

  return res;
};

export const getLangFromShortCode = (shortCode: string): TLangValue => {
  return (
    Object.values(LANGUAGES).find(
      (lang) => lang.shortCode === shortCode.toUpperCase(),
    ) || LANGUAGES.FRENCH
  );
};

/**
 * Full : 1
 * Parcial : 0
 * None : -1
 */
export function getTranslationsStatus(
  data: Array<string | undefined>,
): TriState {
  if (data.every((str) => isValidNotEmptyString(str))) return 1;

  if (data.every((str) => !isValidNotEmptyString(str))) return -1;

  return 0;
}

export const getTranslationStatusColor = (
  data: Array<string | undefined>,
  colors: ReturnType<typeof useCustomColors>,
) => {
  const status = getTranslationsStatus(data);

  switch (status) {
    case 1:
      return colors.green;
    case -1:
      return colors.red;
    case 0:
      return colors.grey;
    default:
      return colors.grey;
  }
};

export const getTranslationStatusIcon = (
  data: Array<string | undefined>,
  fontSize: IconProps["fontSize"] = "medium",
) => {
  const status = getTranslationsStatus(data);

  switch (status) {
    case 1:
      return <CheckCircleIcon color="success" fontSize={fontSize} />;
    case -1:
      return <CancelIcon color="error" fontSize={fontSize} />;
    case 0:
      return <RemoveCircleIcon color="disabled" fontSize={fontSize} />;
    default:
      return <RemoveCircleIcon color="disabled" fontSize={fontSize} />;
  }
};
