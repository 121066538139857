import { collection, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";

import { ATOMS } from "../../recoil/atom";
import { IVersionType } from "../../types/versions";
import { createSnackBarContent } from "../global/useSnackBar";
import { fireDatabase } from "../../config/firebase";
import { needRequestCollections } from "../../constants/access";
import useNeedRequestVisibilityHelper from "../data/useNeedRequestVisibilityHelper";
import { useSetAtom } from "jotai";
import { useTranslation } from "react-i18next";

const collectionRequired = [needRequestCollections.VERSIONS];

const useVersionsTypesList = () => {
  const { t } = useTranslation(["snackbar"]);

  const popSnackbar = useSetAtom(ATOMS.SNACKBAR);

  const setVersionsTypesList = useSetAtom(ATOMS.VERSIONS_TYPES);

  const [unSubscribe, setUnSubscribe] = useState<(() => void) | null>(null);

  const canLoadData = useNeedRequestVisibilityHelper(() => {
    unSubscribe && unSubscribe();
  }, collectionRequired);

  // Versions types list
  useEffect(() => {
    if (canLoadData) {
      const unsub = onSnapshot(
        collection(fireDatabase, "versions", "types", "data"),
        (snap) => {
          const dataVersionsTypes: IVersionType[] = [];
          snap.docs.forEach((doc) => {
            const data = doc.data() as IVersionType;
            if (data) {
              data.available_for = data.available_for ?? [];
              data.public_name = data.public_name ?? {};

              dataVersionsTypes.push(data as IVersionType);
            }
          });
          setVersionsTypesList(
            dataVersionsTypes.sort((a, b) => a.name.localeCompare(b.name)),
          );
        },
        (err) => {
          console.error(err);
          popSnackbar(
            createSnackBarContent({
              content: t("snackbar:request_versions_types_error"),
              severity: "error",
            }),
          );
          setVersionsTypesList([]);
        },
      );

      setUnSubscribe(() => unsub);
      return () => unsub();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canLoadData]);

  return null;
};

export default useVersionsTypesList;
