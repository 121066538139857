import { DrawerHeading, DrawerListSubHeader } from "../Drawer.styled";
import {
  MENU_COLLECTION_REQUIRED,
  PAGE_COLLECTION_REQUIRED,
} from "../../../constants/access";

import { DRAWER_MENU } from "../../../constants/menu";
import { Divider } from "@mui/material";
import { DrawerSectionProps } from "../../../types/DrawerSection";
import EnsureUserHasAccess from "../../../security/EnsureUserHasAccess";
import MenuItem from "../../Menu/MenuItem";
import { useTranslation } from "react-i18next";

const MobileApp = ({ open }: DrawerSectionProps) => {
  const { t } = useTranslation();

  return (
    <EnsureUserHasAccess
      collections={MENU_COLLECTION_REQUIRED.MOBILE_APP}
      collectionStrategy="any"
      redirect={false}
    >
      <DrawerHeading sx={{ ...(open && { p: 0 }) }}>
        {open ? (
          <DrawerListSubHeader>{t("application_label")}</DrawerListSubHeader>
        ) : null}

        <EnsureUserHasAccess
          collections={PAGE_COLLECTION_REQUIRED.GUI}
          redirect={false}
        >
          <MenuItem open={open} element={DRAWER_MENU.GUI} />
        </EnsureUserHasAccess>
      </DrawerHeading>
      {!open ? <Divider /> : null}
    </EnsureUserHasAccess>
  );
};

export default MobileApp;
