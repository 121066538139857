import CustomConfirmButton from "./CustomConfirmButton";
import CustomLoadingButton from "./CustomLoadingButton";

const buttonCommonStyles: { [key: string]: React.CSSProperties } = {
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  wrapper: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",
    width: "100%",
  },
  button: {
    //
  },
  iconButton: {
    padding: 1,
    transition: "color .15s ease-out",
  },
  loadingButton: {
    //
  },
} as const;

export { CustomConfirmButton, CustomLoadingButton, buttonCommonStyles };
