import { IToolID, IToolsConfigRobot } from "./tool";

import { IExportedTimestamp } from "./firebase";
import { IIndexable } from "./utils";
import { IStamp } from "./ros";
import { IStatusCode } from "./statusCode";
import { IWorkDoneRow } from "./work";
import { Timestamp } from "firebase/firestore";

export type TriState = -1 | 0 | 1;

export interface ISimpleRobot {
  id: string;
  name: string;
}

export interface IBasicRobot extends ISimpleRobot {
  email: string;
  robot_number: number;
  client_name: string;
  wine_basin: string;
  distributor: string;
  version_type_id: string;
}

export const isInstanceOfIBasicRobot = (object: any): object is IBasicRobot => {
  return "robot_number" in object && "email" in object;
};

export interface IRobotWithCurrentVersions extends IBasicRobot {
  current_bakus_version: string | null | undefined;
  current_autopilot_version: string | null | undefined;
  current_wizard_version: string | null | undefined;
  current_network_version: string | null | undefined;
  current_gui_native_version: string | null | undefined;
  current_gui_version: string | null | undefined;
}

export interface IRobotStatus {
  av: string;
  data_index: number;
  date_robot: number;
  date_server: Timestamp;
  drs: IStamp;
  key: string;
  value: any;
}

export interface IRobotAutonomousStatus extends IRobotStatus {
  value: IAutonomousStatus;
}
export interface IRobotChargingStatus extends IRobotStatus {
  value: boolean;
}
export interface IRobotBatteryStatus extends IRobotStatus {
  value: number;
}
export interface IRobotEsTopStatus extends IRobotStatus {
  value: TriState;
}

export interface IRobotUserConnectedStatus extends IRobotStatus {
  value: IUserConnectedStatus;
}

export interface IRobotToolPresentStatus extends IRobotStatus {
  value: IToolPresentStatus;
}

export interface IRobotToolsLiftCorrectionStatus extends IRobotStatus {
  value: IToolsLiftCorrectionStatus;
}

export interface IRobotToolsIntercepCorrectionsStatus extends IRobotStatus {
  value: IToolsIntercepCorrectionsStatus;
}

export interface IRobotToolsAvailableStatus extends IRobotStatus {
  value: IToolsAvailableStatus[];
}
export interface IRobotNetworkUpgradeStatus extends IRobotStatus {
  value: INetworkUpgradeStatus;
}

export interface IRobotLockStatusStatus extends IRobotStatus {
  value: ILockStatus;
}
export interface IRobotLateralCorrectionStatus extends IRobotStatus {
  value: ILateralCorrectionStatus;
}

export interface IRobotHardwareVersionStatus extends IRobotStatus {
  value: IHardwareVersionStatus[];
}

export interface IRobotGPSStatus extends IRobotStatus {
  value: IRobotGPS;
}

export interface IRobotEquippedToolStatus extends IRobotStatus {
  value: IEquippedToolStatus;
}
export interface IRobotCurrentEmergencyStatus extends IRobotStatus {
  value: ICurrentEmergencyStatus;
}
export interface IRobotCurrentEmergencyStatusWithID
  extends IRobotCurrentEmergencyStatus {
  id: string;
}

export interface IRobotCurrentControllerStatus extends IRobotStatus {
  value: IRobotUser;
}
export interface IRobotCapabilitiesStatus extends IRobotStatus {
  value: IRobotCapabilities;
}

export interface IRobotBakusWizardStatus extends IRobotStatus {
  value: IBakusWizardStatus;
}
export interface IRobotAutopilotVersionStatus extends IRobotStatus {
  value: IAutopilotVersion;
}

export interface IAutonomousStatus {
  start_date: number;
  status: boolean;
  reason: IStatusCode;
}
export interface IBakusWizardStatus {
  commit_hash: string;
  flag: string;
  pretty_version: string;
  version: string;
}

export interface IRobotCapabilities {
  [key: string]: number;
}

export interface ICurrentEmergencyStatus {
  level: number;
  level_label: string;
  reason: IStatusCode;
  user: IRobotUser;
}

export interface IEquippedToolStatus {
  left: string;
  right: string;
}

export interface IHardwareVersionStatus {
  board_version: string;
  branch_name: string;
  commit_hash: string;
  name: string;
  version_major: number;
  version_minor: number;
}

export interface ILateralCorrectionStatus {
  max: number;
  step: number;
  value: number;
}

export interface ILockStatus {
  level: number;
  level_label: string;
  locks: IRobotLock[];
}

interface IRobotLock {
  level: number;
  reason: IStatusCode;
  stamp: IStamp;
  user: IRobotUser;
}

export interface IRobotUser {
  name: string;
  type: string;
}

export interface INetworkUpgradeStatus {
  data: string;
}

export interface IToolsAvailableStatus {
  id: string;
  name: string;
  types: string[];
}

export interface IToolsIntercepCorrectionsStatus {
  default_config: string;
  blade_torque: {
    max: number;
    min: number;
    step: number;
    value: number;
  };
  sensor_torque: {
    max: number;
    min: number;
    step: number;
    value: number;
    activated: boolean;
  };
}

export interface IRobotToolsConfigStatus extends IRobotStatus {
  value: IToolsConfigRobot;
}

export interface IToolsLiftCorrectionStatus {
  left_value: number;
  right_value: number;
  max: number;
  min: number;
  step: number;
}

export interface IToolPresentStatus {
  is_floating_toolbar: boolean;
  present: IToolID[];
}

export interface IUserConnectedStatus extends IIndexable {
  app_version: string;
  app_native_version: string;
  env: string;
  has_kiosk: boolean;
  kiosk_info: {
    android_version: string;
    battery: number;
    device_id: string;
    device_model: string;
    device_serial: string;
    imei: string;
    ip: string;
    sim_serial: string;
    ssid: string;
    version: string;
    version_code: string;
    wifi_enabled: boolean;
    wifi_signal: number;
  };
  socket_id: string;
  start_url: string;
  user: string;
}

export interface IRobotOverviewInfo {
  active: boolean;
  autonomous: boolean;
  autonomous_time: number | null;
  autonomous_start_date: number;
  autopilot_version: IAutopilotVersion;
  battery_status: number;
  current_parcel: string;
  current_row: number;
  gps_back_status: string;
  gps_front_status: string;
  gps_date: Timestamp | IExportedTimestamp;
  gui_version: string;
  id: string;
  is_charging: boolean;
  latitude: number;
  longitude: number;
  low_level_version: IBakusWizardStatus | undefined;
  name: string;
  network_upgrade_version: INetworkUpgradeStatus | undefined;
  parcel_rows: number;
  robot_number: number;
  tools_config: IToolsConfigRobot | undefined;
  type: string;
}

export const isInstanceOfIRobotOverviewInfo = (
  object: any,
): object is IRobotOverviewInfo => {
  return "autonomous" in object && "autonomous_start_date" in object;
};

export interface IAutopilotVersion {
  version: string;
  commit_hash: string;
  branch: string;
  build_time: IStamp;
}

export interface IRobotGPS {
  position: IRobotPosition;
  orientation: IRobotOrientation;
  status_back: string;
  status_front: string;
}

export interface IRobotOrientation {
  x: number;
  y: number;
  z: number;
  w: number;
}

export interface IRobotPosition {
  latitude: number;
  longitude: number;
}

export type IBaseRobotOrder =
  | {
      id: string;
      answered: false;
      order: IBaseRobotOrderRequest;
    }
  | {
      id: string;
      answered: true;
      order: IBaseRobotOrderRequest;
      response?: IBaseRobotOrderResponse;
    };

export interface IRobotOrder<T, U> {
  answered: boolean;
  order: IRobotOrderRequest<T>;
  response?: IRobotOrderResponse<U>;
}

interface IBaseRobotOrderRequest {
  call: string;
  context: IBaseRobotOrderRequestContext;
  date_server: Timestamp;
  date_user: Timestamp;
  payload: any;
}

interface IBaseRobotOrderRequestContext {
  userId: string;
  version: string;
  fleet?: boolean;
  robot?: boolean;
  robotId?: string;
  source?: string;

  // other
  [key: string]: unknown;
}

interface IRobotOrderRequest<T> extends IBaseRobotOrderRequest {
  payload: T;
}

interface IBaseRobotOrderResponse {
  date_server: Timestamp;
  date_robot: Timestamp;
  payload: any;
}

interface IRobotOrderResponse<U> extends IBaseRobotOrderResponse {
  payload: U;
}

export interface IRobotOrderResponsePayloadWorkSetMaxSpeed {
  speed: number;
}
export interface IRobotOrderResponsePayloadWorkSetLateralCorrection {
  value: number;
}

export interface IRobotOrderRequestWorkGenerate {
  ignored_rows: IWorkDoneRow[];
  loop_mode: boolean;
  side_to_work: string;
  skip_row: boolean;
}

export interface IRobotOrderRequestSetTools {
  left: string;
  right: string;
}

export interface IRobotOrderRequestSetToolbarsCorrection {
  left: number;
  right: number;
}

export interface IRobotOrderResponseToolbarsCorrection {
  left: number;
  right: number;
  max: number;
  min: number;
  step: number;
}

export interface IRobotOrderRequestSetMowerCorrections {
  speed: number;
}

export interface IRobotOrderRequestSetLiftCorrections {
  correctionLeft: number;
  correctionRight: number;
  side: string;
}

export interface IRobotOrderRequestSetIntercepsCorrections {
  blade_torque: number;
  default_config: string;
  sensor_activate: boolean;
  sensor_torque: number;
}

export interface IRobotOrderRequestSetConfig {
  config_name: string;
  config_json: string;
}

export interface IRobotOrderRequestSetToolsActivate {
  activated: boolean;
}

export interface IRobotOrderRequestSetIncidentDescription {
  category: string;
  description: string;
}
export interface IRobotOrderRequestSaveIncident {
  micro: boolean;
  name: string;
  temp: boolean;
}
export interface IRobotOrderRequestEmergencyOrder {
  lvl: number;
  name: string;
  type: string;
}

export interface IRobotOrderDefaultResponse {
  status: IStatusCode;
  success: boolean;
}

export interface IRobotWifiConfig {
  ssid: string;
  password: string;
  bssid: string;
}
