import { FixedPageCenter } from "../../styled/Global/container.styled";
import ProgressLoader from "../ProgressLoader";

const FixedSpinner = () => (
  <FixedPageCenter>
    <ProgressLoader />
  </FixedPageCenter>
);

export default FixedSpinner;
