import { ATOMS } from "../../recoil/atom";
import { fireAuth } from "../../config/firebase";
import { isDefined } from "../../utils/object";
import { onAuthStateChanged } from "firebase/auth";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSetAtom } from "jotai";

const useAuthStateChanged = () => {
  const navigate = useNavigate();

  const setUser = useSetAtom(ATOMS.USER_CURRENT);

  const setWaitFirebaseInit = useSetAtom(ATOMS.UI_WAITING_FIREBASE_INIT);

  useEffect(() => {
    console.log("Init onOnAuthStateChanged");

    onAuthStateChanged(fireAuth, (user) => {
      const newUser = isDefined(user)
        ? {
            displayName: user.displayName ?? "",
            email: user.email ?? "",
            uid: user.uid,
          }
        : null;
      setUser(newUser);
      setWaitFirebaseInit(false);
      if (!isDefined(newUser)) {
        navigate("/login", { replace: true });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useAuthStateChanged;
