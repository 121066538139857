import {
  IAccessEntryForCollection,
  IAccessEntryRoleAccess,
} from "../../types/access";
import { collection, onSnapshot, query } from "firebase/firestore";
import { useEffect, useState } from "react";

import { ATOMS } from "../../recoil/atom";
import { createSnackBarContent } from "../global/useSnackBar";
import { fireDatabase } from "../../config/firebase";
import { needRequestCollections } from "../../constants/access";
import useNeedRequestVisibilityHelper from "../data/useNeedRequestVisibilityHelper";
import { useSetAtom } from "jotai";
import { useTranslation } from "react-i18next";

const collectionRequired = [needRequestCollections.ACCESS_ROLES];
const useAccessRoles = () => {
  const { t } = useTranslation(["snackbar"]);

  const popSnackbar = useSetAtom(ATOMS.SNACKBAR);
  const setAccessRoles = useSetAtom(ATOMS.ACCESS_ROLES);

  const [unSubscribe, setUnSubscribe] = useState<(() => void) | null>(null);

  const canLoadData = useNeedRequestVisibilityHelper(() => {
    unSubscribe && unSubscribe();
  }, collectionRequired);

  // Access list
  useEffect(() => {
    if (canLoadData) {
      const unsub = onSnapshot(
        query(
          collection(fireDatabase, "access", "role_based_access_data", "roles"),
        ),
        (docs) => {
          const reqRoles: IAccessEntryForCollection[] = [];

          docs.forEach((doc) => {
            reqRoles.push({
              collection: doc.id,
              rights: doc.data() as IAccessEntryRoleAccess,
            });
          });

          setAccessRoles(reqRoles);
        },
        (err) => {
          console.error(err);
          popSnackbar(
            createSnackBarContent({
              content: t("snackbar:request_access_roles_error"),
              severity: "error",
            }),
          );
          setAccessRoles([]);
        },
      );
      setUnSubscribe(() => unsub);
      return () => unsub();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canLoadData]);

  return null;
};

export default useAccessRoles;
