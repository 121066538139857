import { Route, Routes as Switch } from "react-router-dom";
import { Suspense, lazy } from "react";

import FixedSpinner from "./components/Global/FixedSpinner";
import FleetScaffold from "./components/Global/FleetScaffold";
import NotFoundPage from "./pages/NotFoundPage";
import RequireAuth from "./security/RequireAuth";

const LoginPage = lazy(() => import("./pages/LoginPage"));

const DefaultFallback = () => <FixedSpinner />;

const Routes = () => (
  <Switch>
    <Route
      path="/login"
      element={
        <Suspense fallback={<DefaultFallback />}>
          <LoginPage />
        </Suspense>
      }
    />

    <Route
      path="/*"
      element={
        <RequireAuth>
          <FleetScaffold />
        </RequireAuth>
      }
    />

    {/* On tombe jamais sur cette page j'ai l'impression :D */}
    <Route
      path="*"
      element={
        <Suspense fallback={<DefaultFallback />}>
          <NotFoundPage />
        </Suspense>
      }
    />
  </Switch>
);

export default Routes;
