export const makeId = (length = 5) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const emailRegex =
  // eslint-disable-next-line
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const retrieveCleanMail = (mail: string) => {
  if (isValidNotEmptyString(mail) && !mail.includes("@")) {
    return `${mail}@vitibot.fr`;
  }
  return mail;
};

export const validateEmail = (email: string) => {
  return emailRegex.test(String(email).toLowerCase());
};

export const isValidNotEmptyString = (
  str: string | null | undefined,
): str is string => {
  if (str && typeof str === "string" && str.length > 0) return true;
  return false;
};

export function isAnyValidNotEmptyString(str: any[]): boolean {
  return str.some(isValidNotEmptyString);
}

export function areValidNotEmptyStrings(str: any[]): boolean {
  return str.every(isValidNotEmptyString);
}

export function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function isUnknown(str: string | null | undefined): boolean {
  return !isValidNotEmptyString(str) || str?.toUpperCase() === "UNKNOWN";
}

export const ensureUrlHasTailingSlash = (url: string) => {
  try {
    const urlObj = new URL(url);

    const cleaned = `${urlObj.origin}${urlObj.pathname}`;

    if (cleaned.endsWith("/")) return cleaned;
    if (cleaned.endsWith("/*")) return cleaned.substring(0, cleaned.length - 1);
    if (!cleaned.endsWith("/")) return `${cleaned}/`;

    throw new Error(`Url ${url} can't be cleaned properly`);
  } catch (e) {
    // console.error(e);
    throw e;
  }
};
