import {
  IAutopilotVersion,
  IBakusVersion,
  IGuiNativeVersion,
  IGuiVersion,
  INetworkVersion,
  IWizardVersion,
} from "../types/versions";
import {
  IBasicRobot,
  IRobotOverviewInfo,
  IRobotWithCurrentVersions,
  ISimpleRobot,
  isInstanceOfIBasicRobot,
  isInstanceOfIRobotOverviewInfo,
} from "../types/robot";

import { DateTime } from "luxon";
import { IAnalyticsInput } from "../types/analytics";
import { TPartialOverviewInfo } from "../types/overview";
import { findFromVersionString } from "./versions";
import { getBakusVersionFromDetails } from "./versions/bakusVersions";
import { isValidNotEmptyString } from "./string";

export const isErrorCodeInfo = (errorCode: string | undefined | null) =>
  isValidNotEmptyString(errorCode) && (errorCode as string)[0] === "i";

export const nameCleaner = (name: string) => {
  let cleanName = name;
  if (typeof name === "string" && name.includes("@")) {
    cleanName = name.substring(name.indexOf("@"), 0);
  }
  return cleanName;
};

export const extractRobotNumberFromName = (name: string) => {
  let number = -1;
  try {
    const mt = name.match(/\d+/);
    if (mt != null) {
      number = parseInt(mt[0]);
    }
  } catch (error) {
    console.log("Cannot retrieve robot number");
  } finally {
    return number;
  }
};

export const getCleanSavName = (robotEmail: string) => {
  robotEmail = nameCleaner(robotEmail);
  if (robotEmail.startsWith("bk")) {
    robotEmail = robotEmail.substring(2);
  }
  return robotEmail;
};

export const autonomousDuration = (robot: IRobotOverviewInfo) =>
  DateTime.now().diff(DateTime.fromSeconds(robot.autonomous_start_date));

/**
 * sortRobotsByName
 */
export const sortRobotsByName = (a: string, b: string) => {
  return a > b ? 1 : b > a ? -1 : 0;
  // return a.name.localeCompare(b.name); // ??
};

/**
 * sortRobotsByNumber
 */
export const sortRobotsByNumber = (
  a: IBasicRobot | IRobotOverviewInfo | IAnalyticsInput,
  b: IBasicRobot | IRobotOverviewInfo | IAnalyticsInput,
) => {
  if (
    a.robot_number !== null &&
    a.robot_number !== undefined &&
    b.robot_number !== null &&
    b.robot_number !== undefined
  ) {
    return a.robot_number - b.robot_number;
  }

  return sortRobotsByName(a.name, b.name);
};

/**
 * sortRobotsByAutonomousStartDate
 */
export const sortRobotsByAutonomousStartDate = (
  a: IRobotOverviewInfo,
  b: IRobotOverviewInfo,
) => {
  if (
    a.autonomous &&
    b.autonomous &&
    a.autonomous_start_date &&
    b.autonomous_start_date
  ) {
    const aTime = autonomousDuration(a).as("minutes");
    const bTime = autonomousDuration(b).as("minutes");
    return aTime > bTime ? 1 : bTime > aTime ? -1 : 0;
  }

  if (a.autonomous) {
    return -1;
  }

  return sortRobotsByNumber(a, b);
};

/**
 * sortRobotsList
 */
export const sortRobotsList = (
  a: ISimpleRobot | IBasicRobot | IRobotOverviewInfo,
  b: ISimpleRobot | IBasicRobot | IRobotOverviewInfo,
) => {
  /**
   * Sort robots of type - IRobotOverviewInfo
   */
  if (isInstanceOfIRobotOverviewInfo(a) && isInstanceOfIRobotOverviewInfo(b)) {
    return sortRobotsByAutonomousStartDate(a, b);
  }

  /**
   * Sort robots of type - IBasicRobot
   */
  if (isInstanceOfIBasicRobot(a) && isInstanceOfIBasicRobot(b)) {
    return sortRobotsByNumber(a, b);
  }

  /**
   * Sort robots of type - ISimpleRobot
   */
  return sortRobotsByName(a.name, b.name);
};

/**
 * getRobotVersionsData
 */
export const getRobotVersionsData = (
  robotWithVersions: IRobotWithCurrentVersions,
  bakusVersionsList: IBakusVersion[],
  autopilotVersionsList: IAutopilotVersion[],
  wizardVersionsList: IWizardVersion[],
  networkVersionsList: INetworkVersion[],
  guiNativeVersionsList: IGuiNativeVersion[],
  guiVersionsList: IGuiVersion[],
  overviewData?: TPartialOverviewInfo,
) => {
  const current_autopilot_version =
    (overviewData
      ? overviewData.autopilot_version?.version
      : robotWithVersions.current_autopilot_version) ?? null;

  const current_wizard_version =
    (overviewData
      ? overviewData.bakus_wizard_version?.version
      : robotWithVersions.current_wizard_version) ?? null;

  const current_network_version =
    (overviewData
      ? overviewData.network_upgrade_version?.data
      : robotWithVersions.current_network_version) ?? null;

  const current_gui_native_version =
    (overviewData
      ? overviewData.user_connected?.app_native_version
      : robotWithVersions.current_gui_native_version) ?? null;

  const current_gui_version =
    (overviewData
      ? overviewData.user_connected?.app_version
      : robotWithVersions.current_gui_version) ?? null;

  // Autopilot
  const matchedAutopilotVersion = findFromVersionString(
    current_autopilot_version,
    autopilotVersionsList,
  );

  // Wizard
  const matchedWizardVersion = findFromVersionString(
    current_wizard_version,
    wizardVersionsList,
  );

  // Network
  const matchedNetworkVersion = findFromVersionString(
    current_network_version,
    networkVersionsList,
  );

  // // Gui
  // const matchedGuiVersion = findFromVersionString(
  //   current_gui_version,
  //   guiVersionsList
  // );

  // Bakus
  const matchedBakusVersion = getBakusVersionFromDetails(
    bakusVersionsList,
    matchedAutopilotVersion?.id,
    matchedWizardVersion?.id,
    matchedNetworkVersion?.id,
    // matchedGuiVersion?.id
  );

  // Robot
  const newRobotWithVersions: IRobotWithCurrentVersions = {
    ...robotWithVersions,
    current_bakus_version: matchedBakusVersion?.version ?? null,
    current_autopilot_version,
    current_wizard_version,
    current_network_version,
    current_gui_native_version,
    current_gui_version,
    // robot.network_upgrade_version : getCoercedSemanticVersion(
    //   overviewData.network_upgrade_version?.version
    // ) ?? null,
  };

  return newRobotWithVersions;
};

export const convertToFakeBasicRobot = (robot: ISimpleRobot): IBasicRobot => {
  return {
    id: robot.id,
    name: robot.name,
    email: robot.name,
    robot_number: extractRobotNumberFromName(robot.name),
    client_name: "unknown",
    wine_basin: "unknown",
    distributor: "unknown",
    version_type_id: "",
  };
};

export const convertToSimpleRobot = (robot: IBasicRobot): ISimpleRobot => {
  return {
    id: robot.id,
    name: robot.name,
  };
};
