const PAGE = {
  APP_BAR_HEIGHT: 48,
  NAVBAR_HEIGHT: 73,
  DIVIDER_HEIGHT: 2 * 24 + 1, // Theme spacing 2 (24px) + 1px border
  CONTENT: {
    WIDTH: 1200,
    SPACING: 24,
    PADDING: 16,
  },
  ORNAMENT: {
    HEIGHT: 40,
  },
};

export default PAGE;
