import { IArrayable, IIndexable } from "../types/utils";

import { isDefined } from "./object";

export const findByID = <T extends { id: string }>(
  id: string | null | undefined,
  list: T[],
): T | null => {
  return list.find((item) => item.id === id) ?? null;
};

export const not = <T extends IArrayable | IIndexable>(a: T[], b: T[]) =>
  a.filter((value) => b.indexOf(value) === -1);

export const intersection = <T extends IArrayable | IIndexable>(
  a: T[],
  b: T[],
) => a.filter((value) => b.indexOf(value) !== -1);

export const union = <T extends IArrayable | IIndexable>(a: T[], b: T[]) => [
  ...a,
  ...not(b, a),
];

export function isValidNonEmptyArray<T>(arr: T): boolean {
  return isDefined(arr) && Array.isArray(arr) && arr.length > 0;
}

export function areValidNonEmptyArrays(arrOfArr: any[]): boolean {
  return arrOfArr.every(isValidNonEmptyArray);
}
