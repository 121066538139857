import { DateTime, Duration } from "luxon";

import { IExportedTimestamp } from "../types/firebase";
import { Timestamp } from "firebase/firestore";

const options: Intl.DateTimeFormatOptions = {
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  month: "numeric",
  second: "numeric",
  year: "numeric",
};

/**
 * Sort dates from oldest to newest
 */
export const sortByDateAscending = (a: DateTime, b: DateTime) => {
  return a.toMillis() - b.toMillis();
};

/**
 * Sort dates from newest to oldest
 */
export const sortByDateDescending = (a: DateTime, b: DateTime) => {
  return b.toMillis() - a.toMillis();
};

export const getDateFromTimestamp = (timestamp: Timestamp) => {
  return DateTime.fromJSDate(timestamp.toDate());
};

export const formatTimestamp = (lang: string, date: { seconds: number }) =>
  formatUNIX(lang, date.seconds * 1000);

export const formatUNIX = (lang: string, date: number) =>
  new Date(date).toLocaleDateString(lang, options);

export const unknownTimeFormatToTimestamp = (date: unknown): Timestamp => {
  if (Object.prototype.hasOwnProperty.call(date, "_seconds")) {
    return new Timestamp(
      (date as IExportedTimestamp)._seconds,
      (date as IExportedTimestamp)._nanoseconds,
    );
  }
  if (Object.prototype.hasOwnProperty.call(date, "seconds")) {
    return new Timestamp(
      (date as Timestamp).seconds,
      (date as Timestamp).nanoseconds,
    );
  }
  if (typeof date === "string") {
    return Timestamp.fromDate(new Date(date));
  }
  return date as Timestamp;
};

export const retrieveDayBetweenDates = (
  startDate: DateTime,
  endDate: DateTime,
  timeFormatComparison: string,
): string[] => {
  const retVal: string[] = [];
  let now = startDate;

  while (now <= endDate) {
    retVal.push(now.toFormat(timeFormatComparison));
    now = now.plus({ days: 1 });
  }

  return retVal;
};

export const elapsedTimeInMinutes = (start: number, end: number): string => {
  return DateTime.fromMillis(end)
    .diff(DateTime.fromMillis(start))
    .shiftTo("minute")
    .toHuman({
      unitDisplay: "short",
      useGrouping: true,
      maximumFractionDigits: 0,
    });
};

export const formatDuration = (duration: number, displayFullSeconds = true) => {
  const s = Math.trunc(duration / 1000) % 60;
  if (duration < 60000) {
    return displayFullSeconds ? `${s} s` : `quelques secondes`;
  }
  const min = Math.trunc(duration / 60000) % 60;
  if (duration < 3600000) {
    return displayFullSeconds ? `${min} min ${s} s` : `${min} min`;
  }
  const h = Math.trunc(duration / 3600000);
  if (duration < 86400000) {
    return `${h} h ${min} min`;
  }
  const j = Math.trunc(duration / 86400000);

  return `${j} j`;
};

export const formatDurationInHoursMinutes = (duration: Duration) => {
  const days = Math.floor(duration.as("days"));

  let output = "";

  if (days > 0) {
    output += `${days.toFixed(0)}:`;
  }

  const hours = Math.floor(duration.as("hours")) % 24;
  const minutes = Math.floor(duration.as("minutes")) % 60;
  const seconds = Math.floor(duration.as("seconds")) % 60;

  if (hours > 0) {
    output += hours.toString().padStart(2, "0") + ":";
  } else if (days > 0) {
    output += "00:";
  }

  output += `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;

  return output;
};
