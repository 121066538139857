import { DrawerHeading, DrawerListSubHeader } from "../Drawer.styled";
import {
  MENU_COLLECTION_REQUIRED,
  PAGE_COLLECTION_REQUIRED,
  PAGE_ROLE_REQUIRED,
  needRequestCollections,
} from "../../../constants/access";
import { useEffect, useState } from "react";

import { ATOMS } from "../../../recoil/atom";
import { DRAWER_MENU } from "../../../constants/menu";
import { Divider } from "@mui/material";
import { DrawerSectionProps } from "../../../types/DrawerSection";
import EnsureUserHasAccess from "../../../security/EnsureUserHasAccess";
import MenuItem from "../../Menu/MenuItem";
import { TDrawerElement } from "../../../types/menu";
import { isDefined } from "../../../utils/object";
import { useAtomValue } from "jotai";
import useCollectionProvider from "../../../hooks/data/useCollectionProvider";
import { useTranslation } from "react-i18next";

const requiredCollections = [needRequestCollections.COUNTERS];
const Management = ({ open }: DrawerSectionProps) => {
  const { t } = useTranslation();
  useCollectionProvider(requiredCollections);

  const counterAccess = useAtomValue(ATOMS.COUNTER_ACCESS);

  const [userDrawerMenu, setUserDrawerMenu] = useState<TDrawerElement>(
    DRAWER_MENU.USERS_MANAGEMENT,
  );

  useEffect(() => {
    const devUsers =
      isDefined(counterAccess) && isDefined(counterAccess.roles)
        ? counterAccess.roles.find((r) => r.name === "dev")?.number ?? 0
        : 0;

    setUserDrawerMenu({
      ...DRAWER_MENU.USERS_MANAGEMENT,
      badgeCount: devUsers > 0 ? devUsers : undefined,
    });
  }, [counterAccess]);

  return (
    <EnsureUserHasAccess
      collections={MENU_COLLECTION_REQUIRED.MANAGEMENT}
      collectionStrategy="any"
      redirect={false}
    >
      <DrawerHeading sx={{ ...(open && { p: 0 }) }}>
        {open ? (
          <DrawerListSubHeader>{t("management_label")}</DrawerListSubHeader>
        ) : null}
        <EnsureUserHasAccess
          collections={PAGE_COLLECTION_REQUIRED.USERS_MANAGEMENT}
          redirect={false}
        >
          <MenuItem open={open} element={userDrawerMenu} />
        </EnsureUserHasAccess>

        <EnsureUserHasAccess
          collections={PAGE_COLLECTION_REQUIRED.ROBOTS_MANAGEMENT}
          redirect={false}
        >
          <MenuItem open={open} element={DRAWER_MENU.ROBOTS_MANAGEMENT} />
        </EnsureUserHasAccess>

        <EnsureUserHasAccess
          collections={PAGE_COLLECTION_REQUIRED.PARCELS_MANAGEMENT}
          redirect={false}
        >
          <MenuItem open={open} element={DRAWER_MENU.PARCELS_MANAGEMENT} />
        </EnsureUserHasAccess>

        <EnsureUserHasAccess
          collections={PAGE_COLLECTION_REQUIRED.MAP_MANAGEMENT}
          redirect={false}
        >
          <MenuItem open={open} element={DRAWER_MENU.MAP_MANAGEMENT} />
        </EnsureUserHasAccess>

        <EnsureUserHasAccess
          collections={PAGE_COLLECTION_REQUIRED.STATUS_CODES_MANAGEMENT}
          redirect={false}
        >
          <MenuItem open={open} element={DRAWER_MENU.STATUS_CODES_MANAGEMENT} />
        </EnsureUserHasAccess>

        <EnsureUserHasAccess
          collections={PAGE_COLLECTION_REQUIRED.LOGS_MANAGEMENT}
          redirect={false}
        >
          <MenuItem open={open} element={DRAWER_MENU.LOGS_MANAGEMENT} />
        </EnsureUserHasAccess>

        <EnsureUserHasAccess
          roles={PAGE_ROLE_REQUIRED.VERSIONS}
          roleStrategy="any"
          collections={PAGE_COLLECTION_REQUIRED.VERSIONS_MANAGEMENT}
          redirect={false}
        >
          <MenuItem open={open} element={DRAWER_MENU.VERSIONS_MANAGEMENT} />
        </EnsureUserHasAccess>

        <EnsureUserHasAccess
          roles={PAGE_ROLE_REQUIRED.DISTRIBUTOR}
          roleStrategy="any"
          // collections={PAGE_COLLECTION_REQUIRED.DISTRIBUTOR_MANAGEMENT}
          redirect={false}
        >
          <MenuItem open={open} element={DRAWER_MENU.DISTRIBUTOR_MANAGEMENT} />
        </EnsureUserHasAccess>
      </DrawerHeading>
      {!open ? <Divider /> : null}
    </EnsureUserHasAccess>
  );
};

export default Management;
