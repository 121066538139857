import {
  Box,
  CSSObject,
  Drawer,
  List,
  ListSubheader,
  Theme,
  styled,
} from "@mui/material";

import { DRAWER_WIDTH } from "../../constants/menu";

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
});

export const FleetDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
  name: "FleetDrawer",
})(({ theme, open }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const DrawerHeading = styled(List, {
  name: "DrawerHeading",
})(
  ({ theme }) => `
  background-color: ${theme.palette.background.paper};
`,
);

export const DrawerListSubHeader = styled(ListSubheader, {
  name: "DrawerListSubHeader",
})(`
  z-index: 2;
`);

export const DrawerFooter = styled(Box, {
  name: "DrawerFooter",
})(`
  width: 100%;
`);
