import i18n, { Resource, ResourceKey, ResourceLanguage } from "i18next";

import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const supportedLanguages = ["fr", "en"];
const i18nNamespaces = [
  "access",
  "activity",
  "app_bar_title",
  "common",
  "config",
  "distributor",
  "fully",
  "login",
  "logs",
  "map",
  "not_found_page",
  "overview",
  "poi",
  "report",
  "robot",
  "row_extremity",
  "sav",
  "snackbar",
  "status",
  "storage",
  "tools",
  "user",
  "version",
  "wifi",
];

const getI18nResources = (languages: string[], namespaces: string[]) => {
  const resources: Resource = {};

  const langResources = import.meta.glob("./locales/**/*.json", {
    eager: true,
  }) as Record<string, { default: ResourceKey }>;

  languages.forEach((language) => {
    const lang: ResourceLanguage = {};
    namespaces.forEach((namespace) => {
      try {
        // console.log('Try to load locale file', `./locales/${language}/${namespace}.json`);
        const langData =
          langResources[`./locales/${language}/${namespace}.json`].default;
        lang[namespace] = langData;
      } catch (err) {
        console.log(
          "Unable to load language data for",
          language,
          namespace,
          err,
        );
      }
    });
    resources[language] = lang;
  });

  return resources;
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      debug: import.meta.env.MODE !== "production",
      defaultNS: "common",
      detection: {
        order: ["localStorage", "cookie", "navigator", "path"],
      },
      fallbackLng: "en",
      interpolation: {
        escapeValue: false,
      },
      keySeparator: false, // we use content as keys

      // have a common namespace used around the full app
      ns: ["common"],

      // we init with resources
      resources: getI18nResources(supportedLanguages, i18nNamespaces),
    },
    (err, t) => {
      /* resources are loaded */
    },
  );

export default i18n;
