import { Box, Button, Typography } from "@mui/material";
import { Location, useLocation, useNavigate } from "react-router-dom";

import BakusIcon from "../utils/img/BakusIcon";
import { BakusIconWrapper } from "./NotFoundPage.styled";
import { PAGE_FALLBACK } from "../constants/access";
import { PageDivider } from "../styled/Global/container.styled";
import { isDefined } from "../utils/object";
import { isValidNotEmptyString } from "../utils/string";
import { useTranslation } from "react-i18next";

type NotFoundPageProps = {
  denied?: boolean;
};

type LocationState = {
  from?: Location;
};

const NotFoundPage = ({ denied = false }: NotFoundPageProps) => {
  const { t } = useTranslation(["not_found_page"]);
  const location = useLocation();
  const state = location.state as LocationState; // dirty typing
  const navigate = useNavigate();

  return (
    <Box
      height="35%"
      display="flex"
      alignItems="flex-end"
      justifyContent="center"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <BakusIconWrapper viewBox="0 0 68 68" denied={denied}>
          <path d={BakusIcon} />
        </BakusIconWrapper>

        {denied ? null : (
          <Typography component="h1" variant="h2" fontWeight="bold">
            404
          </Typography>
        )}

        <Typography component="h2" variant="h6">
          {denied ? t("not_found_page:denied") : t("not_found_page:notFound")}
        </Typography>

        {denied ? (
          <>
            <PageDivider />
            {isDefined(state) &&
            isDefined(state.from) &&
            isValidNotEmptyString(state.from.pathname) ? (
              <Button
                variant="contained"
                onClick={() => {
                  // ! already checked above
                  // @ts-ignore
                  navigate(state.from.pathname, { replace: true });
                }}
              >
                {t("not_found_page:goBack")}
              </Button>
            ) : location.pathname !== PAGE_FALLBACK ? (
              <Button
                variant="contained"
                onClick={() => {
                  navigate(PAGE_FALLBACK, { replace: true });
                }}
              >
                {t("not_found_page:goToHome")}
              </Button>
            ) : null}
          </>
        ) : null}
      </Box>
    </Box>
  );
};

export default NotFoundPage;
