import {
  ListItemButton,
  ListItemButtonProps,
  ListItemText,
  ListItemTextProps,
  styled,
} from "@mui/material";

type MenuElementProps = { hasBadge: boolean };

export const MenuElement = styled(
  ({ hasBadge, ...props }: ListItemButtonProps & MenuElementProps) => (
    <ListItemButton {...props} />
  ),
  {
    name: "MenuElement",
  },
)(({ theme, hasBadge }) => {
  if (hasBadge) {
    return `margin-top: ${theme.spacing(1)};`;
  }
  return ``;
});

type MenuElementTextProps = { open: boolean };

export const MenuElementText = styled(
  ({ open, ...props }: ListItemTextProps & MenuElementTextProps) => (
    <ListItemText {...props} />
  ),
  {
    name: "MenuElementText",
  },
)(({ theme, open }) => ({
  marginLeft: theme.spacing(-1.5),
  minWidth: 160,
  whiteSpace: "pre-wrap",
  ...(open === true
    ? { display: "flex", height: "auto" }
    : { height: 0, display: "none" }),
  transition: theme.transitions.create(["height", "display"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.standard,
  }),
}));
