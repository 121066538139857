import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
  progress: {
    left: "50%",
    margin: 0,
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%, -50%)",
  },
});

const CircularIndeterminate = () => {
  const classes = useStyles();

  return (
    <div>
      <CircularProgress className={classes.progress} data-cy="loading" />
    </div>
  );
};

export default CircularIndeterminate;
