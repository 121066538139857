export enum ROLES {
  MASTER_ADMIN = "masteradmin",
  ADMIN = "admin",
  SUPERVISOR = "supervisor",
  DEV = "dev",
  DATA_VIEWER = "dataViewer",
  USER = "user",
  TRADUCTOR = "traductor",
  DISTRIBUTOR_MANAGER = "distributor_manager",
}
