import { IParcelMetaWithId } from "../../types/parcel";
import { IndexedStorage } from "./type";

export class ParcelStorageDB extends IndexedStorage<IParcelMetaWithId> {
  #db: IDBDatabase | null = null;

  constructor() {
    super();
    const parcelDB = window.indexedDB.open("parcelDB", 1);

    parcelDB.onupgradeneeded = this.#onUpgradeNeeded;
    parcelDB.onsuccess = this.#onSuccess;
  }

  #onUpgradeNeeded = (event: IDBVersionChangeEvent) => {
    const db = (event.target as IDBOpenDBRequest).result;

    if (!db.objectStoreNames.contains("parcelStore")) {
      db.createObjectStore("parcelStore", {
        keyPath: "docID",
        autoIncrement: false,
      });
    }
  };

  #onSuccess = (event: Event) => {
    this.#db = (event.target as IDBOpenDBRequest).result;
    console.log("ParcelStorageDB is ready");
  };

  get isReady(): boolean {
    return this.#db !== null;
  }

  getAll = async (
    initialValue: IParcelMetaWithId[],
  ): Promise<IParcelMetaWithId[]> => {
    await this.waitUntilReady();

    if (!this.isReady) {
      throw new Error("ParcelStorageDB not ready");
    }
    if (!this.#db) {
      throw new Error("ParcelStorageDB not ready");
    }

    const transaction = this.#db.transaction("parcelStore", "readonly");
    const parcelStore = transaction.objectStore("parcelStore");
    const request = parcelStore.getAll();

    return new Promise((resolve, reject) => {
      request.onsuccess = () => {
        if (request.result.length === 0) {
          resolve(initialValue); // return initial value
        } else {
          resolve(request.result);
        }
      };

      request.onerror = () => {
        reject(request.error);
      };

      transaction.oncomplete = () => {
        console.log("getAllParcels-tx-complete", request.result.length);
      };
    });
  };

  setAll = async (parcels: IParcelMetaWithId[]): Promise<void> => {
    await this.waitUntilReady();

    if (!this.isReady) {
      throw new Error("ParcelStorageDB not ready");
    }
    if (!this.#db) {
      throw new Error("ParcelStorageDB not ready");
    }

    const transaction = this.#db.transaction("parcelStore", "readwrite");
    const parcelStore = transaction.objectStore("parcelStore");

    for (const parcel of parcels) {
      parcelStore.put(parcel);
    }

    return new Promise((resolve, reject) => {
      transaction.oncomplete = () => {
        resolve();
      };

      transaction.onerror = (event) => {
        reject(transaction.error);
      };
    });
  };
}

export const parcelStorage = new ParcelStorageDB();
