import "./i18n/i18n";
import "./styles/main.css";

import { fireDatabase, fireFunction } from "./config/firebase";

import AppScaffold from "./components/Global/AppScaffold";
import { BrowserRouter } from "react-router-dom";
import Config from "./config/config";
import Routes from "./Routes";
import { connectFirestoreEmulator } from "firebase/firestore";
import { connectFunctionsEmulator } from "firebase/functions";

if (import.meta.env.MODE !== "production" || Config.DOCKER) {
  const hostname = Config.CI ? "backend" : "localhost";

  connectFirestoreEmulator(fireDatabase, hostname, 8090);

  connectFunctionsEmulator(fireFunction, hostname, 5090);
}

const App = () => {
  return (
    <BrowserRouter>
      <AppScaffold>
        <Routes />
      </AppScaffold>
    </BrowserRouter>
  );
};

export default App;
