import { useEffect, useState } from "react";

const useDebounce = <F extends (...args: any[]) => any>(fn: F, ms: number) => {
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  const teardown = () => {
    if (timer) clearTimeout(timer);
  };

  const debouncedFn = (...args: Parameters<F>) =>
    new Promise((resolve) => {
      teardown();

      setTimer(
        setTimeout(() => {
          resolve(fn(...args));
        }, ms),
      );
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => teardown(), []);

  return debouncedFn;
};

export default useDebounce;
