import useAccess from "../hooks/access/useAccess";
import useAccessRoles from "../hooks/access/useAccessRoles";
import useAuthStateChanged from "../hooks/auth/useAuthStateChanged";
import useAutopilotVersionsList from "../hooks/versions/useAutopilotVersionsList";
import useBakusVersionsList from "../hooks/versions/useBakusVersionsList";
import useCounters from "../hooks/counters/useCounters";
import useCurrentUserAccess from "../hooks/user/useCurrentUserAccess";
import useDistributorsList from "../hooks/distributors/useDistributorsList";
import useGuiNativeVersionsList from "../hooks/versions/useGuiNativeVersionsList";
import useGuiVersionsList from "../hooks/versions/useGuiVersionsList";
import useNetworkVersionsList from "../hooks/versions/useNetworkVersionsList";
import useParcels from "../hooks/parcel/useParcels";
import usePointOfInterest from "../hooks/poi/usePointOfInterest";
import useRobotList from "../hooks/robot/useRobotList";
import useStatusCodes from "../hooks/statusCodes/useStatusCodes";
import useToolsConfig from "../hooks/toolsConfig/useToolsConfig";
import useToolsModel from "../hooks/toolsConfig/useToolsModel";
import useUsers from "../hooks/user/useUsers";
import useVersionsCanalsList from "../hooks/versions/useVersionsCanalsList";
import useVersionsTypesList from "../hooks/versions/useVersionsTypesList";
import useWizardVersionsList from "../hooks/versions/useWizardVersionsList";

const useInitHooks = () => {
  // users
  useAuthStateChanged();
  useUsers();
  useCurrentUserAccess();

  // robots
  useRobotList();

  // status codes
  useStatusCodes();

  // parcels
  useParcels();

  // pois
  usePointOfInterest();

  // access
  useAccess();
  useAccessRoles();

  // counter
  useCounters();

  // Distributors
  useDistributorsList();

  // tools config
  useToolsConfig();
  useToolsModel();

  // Versions
  useVersionsTypesList();
  useVersionsCanalsList();
  useBakusVersionsList();
  useAutopilotVersionsList();
  useWizardVersionsList();
  useNetworkVersionsList();
  useGuiNativeVersionsList();
  useGuiVersionsList();

  return null;
};

export default useInitHooks;
