import { ROLES } from "../constants/access/roles";
import { isDefined } from "./object";

export const getUserHighestRole = (userRoles: string[]) => {
  let highestRole: ROLES | null = null;

  /**
   * Note: Array.some() automatically breaks whenever the return value becomes 'true'
   */
  Object.values(ROLES).some((role) => {
    highestRole =
      (userRoles.find((userRole) => userRole === role) as ROLES) ?? null;

    return isDefined(highestRole);
  });

  return highestRole;
};
