import { TranslationHelperType } from "../types/translation";

export enum LANGUAGE_ENUM {
  FR = "FR",
  EN = "EN",
  IT = "IT",
  ES = "ES",
  CS = "CS",
  HU = "HU",
}

const LANGUAGES: {
  [key: string]: {
    label: TranslationHelperType<"common">;
    code: string;
    shortCode: LANGUAGE_ENUM;
  };
} = {
  FRENCH: {
    label: "common:french",
    code: "fr-FR",
    shortCode: LANGUAGE_ENUM.FR,
  },
  ENGLISH: {
    label: "common:english",
    code: "en-US",
    shortCode: LANGUAGE_ENUM.EN,
  },
  ITALIAN: {
    label: "common:italian",
    code: "it-IT",
    shortCode: LANGUAGE_ENUM.IT,
  },
  SPANISH: {
    label: "common:spanish",
    code: "es-ES",
    shortCode: LANGUAGE_ENUM.ES,
  },
  CZECH: {
    label: "common:czech",
    code: "cs-CZ",
    shortCode: LANGUAGE_ENUM.CS,
  },
  HUNGARIAN: {
    label: "common:hungarian",
    code: "hu-HU",
    shortCode: LANGUAGE_ENUM.HU,
  },
} as const;

export default LANGUAGES;

export type TLangValue = {
  label: string;
  code: (typeof LANGUAGES)[keyof typeof LANGUAGES]["code"];
  shortCode: (typeof LANGUAGES)[keyof typeof LANGUAGES]["shortCode"];
};

export const APP_SUPPORTED_LANGUAGES = [
  LANGUAGES.FRENCH,
  LANGUAGES.ENGLISH,
  LANGUAGES.ITALIAN,
  LANGUAGES.SPANISH,
  LANGUAGES.CZECH,
  LANGUAGES.HUNGARIAN,
];
