import { collection, onSnapshot } from "firebase/firestore";
import { extractRobotNumberFromName, sortRobotsList } from "../../utils/robot";
import { useEffect, useState } from "react";

import { ATOMS } from "../../recoil/atom";
import { IBasicRobot } from "../../types/robot";
import { createSnackBarContent } from "../global/useSnackBar";
import { fireDatabase } from "../../config/firebase";
import { needRequestCollections } from "../../constants/access";
import useNeedRequestVisibilityHelper from "../data/useNeedRequestVisibilityHelper";
import { useSetAtom } from "jotai";
import { useTranslation } from "react-i18next";

const collectionRequired = [needRequestCollections.ROBOTS];

const useRobotList = () => {
  const { t } = useTranslation(["snackbar"]);

  const popSnackbar = useSetAtom(ATOMS.SNACKBAR);
  const setRobotsList = useSetAtom(ATOMS.ROBOT_LIST);
  const setRobotDisabledList = useSetAtom(ATOMS.ROBOT_DISABLED_LIST);

  const [unSubscribe, setUnSubscribe] = useState<(() => void) | null>(null);

  const canLoadData = useNeedRequestVisibilityHelper(() => {
    unSubscribe && unSubscribe();
  }, collectionRequired);

  // Robot list
  useEffect(() => {
    if (canLoadData) {
      const unsub = onSnapshot(
        collection(fireDatabase, "robots"),
        (doc) => {
          const robotList: IBasicRobot[] = [];
          const disabledRobotList: IBasicRobot[] = [];

          doc.docs.forEach((doc) => {
            const robotData = doc.data();

            const robotElement: IBasicRobot = {
              email: robotData.email,
              id: robotData.id,
              name: robotData.name,
              robot_number: extractRobotNumberFromName(robotData.name),
              client_name: robotData.client_name,
              wine_basin: robotData.wine_basin,
              distributor: robotData.distributor,
              version_type_id: robotData.version_type_id,
            };
            if (robotData.disabled) {
              disabledRobotList.push(robotElement);
            } else {
              robotList.push(robotElement);
            }
          });

          robotList.sort(sortRobotsList);
          disabledRobotList.sort(sortRobotsList);

          setRobotsList(robotList);
          setRobotDisabledList(disabledRobotList);
        },
        (err) => {
          console.error(err);
          popSnackbar(
            createSnackBarContent({
              content: t("snackbar:request_robots_error"),
              severity: "error",
            }),
          );
          setRobotsList([]);
          setRobotDisabledList([]);
        },
      );
      setUnSubscribe(() => unsub);
      return () => unsub();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canLoadData]);

  return null;
};

export default useRobotList;
