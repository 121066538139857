import { ATOMS } from "../../recoil/atom";
import { needRequestCollections } from "../../constants/access";
import { useEffect } from "react";
import { useSetAtom } from "jotai";

const useCollectionProvider = (collection: needRequestCollections[]) => {
  const setNeedRequest = useSetAtom(ATOMS.NEED_REQUEST);

  useEffect(() => {
    // request needed datas
    setNeedRequest({ add: true, needs: collection });

    return () => setNeedRequest({ add: false, needs: collection });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collection]);
};

export default useCollectionProvider;
