import { parseBoolean } from "../utils/boolean";

const Config = {
  firebase: {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    appId: import.meta.env.VITE_FIREBASE_APP_ID,
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
    messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  },
  CI: parseBoolean(import.meta.env.VITE_APP_CI, false),
  DOCKER: parseBoolean(import.meta.env.VITE_APP_DOCKER, false),
  PACKAGE_VERSION: PACKAGE_VERSION as string,
};

export default Config;
