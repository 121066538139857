import { useEffect, useState } from "react";

import { ATOMS } from "../../recoil/atom";
import { isDefined } from "../../utils/object";
import { useAtomValue } from "jotai";

export type TUseEnsureAccessProps = {
  collections?: readonly string[];
  collectionStrategy?: "all" | "any";
  roles?: readonly string[];
  roleStrategy?: "all" | "any";
  verbose?: boolean;
};

type TUseEnsureAccessHook = (
  props: TUseEnsureAccessProps,
) => boolean | undefined;

const useEnsureHasAccess: TUseEnsureAccessHook = ({
  collections,
  collectionStrategy = "all",
  roles,
  roleStrategy = "all",
  verbose = false,
}) => {
  const userAccess = useAtomValue(ATOMS.USER_CURRENT_ACCESS);
  const userRoles = useAtomValue(ATOMS.USER_CURRENT_ROLES);
  const waitInit = useAtomValue(ATOMS.UI_WAITING_FIREBASE_INIT);

  const [hasAccess, setHasAccess] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    setHasAccess(() => {
      if (waitInit || !isDefined(userRoles) || !isDefined(userAccess)) {
        return undefined;
      }

      /**
       * Returns false by default
       * (if user roles and access lists are empty)
       */
      if (userRoles.length === 0 || userAccess.length === 0) {
        return false;
      }

      /**
       * Makes sure user has access to every collections (if provided)
       */
      if (isDefined(collections) && collections.length !== 0) {
        const strategy = collectionStrategy;
        const hasAccess =
          strategy === "all"
            ? collections.every((c) => userAccess.includes(c))
            : collections.some((c) => userAccess.includes(c));

        if (verbose) {
          console.log(
            "[security] collections",
            collections,
            strategy,
            hasAccess,
          );
        }

        if (!hasAccess) {
          return false;
        }
      }

      /**
       * Makes sure user has access to all roles (if strategy="all")
       */
      if (isDefined(roles) && roles.length !== 0) {
        const strategy = roleStrategy;
        const hasAccess =
          strategy === "all"
            ? roles.every((r) => userRoles.includes(r))
            : roles.some((r) => userRoles.includes(r));

        if (verbose) {
          console.log(
            "[security] roles",
            userRoles,
            roles,
            strategy,
            hasAccess,
          );
        }

        if (!hasAccess) {
          return false;
        }
      }

      return true;
    });

    return () => setHasAccess(undefined);
  }, [
    collectionStrategy,
    collections,
    roleStrategy,
    roles,
    userAccess,
    userRoles,
    verbose,
    waitInit,
  ]);

  return hasAccess;
};

export default useEnsureHasAccess;
