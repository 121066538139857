import {
  Button,
  ButtonProps,
  IconButton,
  IconButtonProps,
} from "@mui/material";

import { ILoadingButtonProps } from "./CustomLoadingButton";
import React from "react";
import { buttonCommonStyles } from "./index";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    ...buttonCommonStyles.root,
  },
  wrapper: {
    ...buttonCommonStyles.wrapper,
  },
  button: {
    // ...buttonCommonStyles.button
  },
  iconButton: {
    transition: buttonCommonStyles.iconButton.transition,
  },
}));

export type ICustomButtonProps = {
  onClick: () => void | Promise<void>;
  icon?: React.ReactNode;
} & (ButtonProps | (ButtonProps & ILoadingButtonProps));

/**
 * Icon type button
 */
const RenderIconButton = (props: ICustomButtonProps) => {
  const classes = useStyles();

  return (
    <IconButton
      {...(props as IconButtonProps)}
      className={clsx(props.className, classes.iconButton)}
    >
      {props.icon}
    </IconButton>
  );
};

/**
 * Regular type button
 */
const RenderRegularButton = (props: ICustomButtonProps) => {
  const classes = useStyles();

  return (
    <Button
      {...(props as ButtonProps)}
      className={clsx(props.className, classes.button)}
    >
      {props.children}
    </Button>
  );
};

/**
 * Custom button component
 */
const CustomButton = (props: ICustomButtonProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        {props.icon ? (
          <RenderIconButton {...props}>{props.children}</RenderIconButton>
        ) : (
          <RenderRegularButton {...props}>{props.children}</RenderRegularButton>
        )}
      </div>
    </div>
  );
};

export default CustomButton;
