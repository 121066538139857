export abstract class IndexedStorage<T> {
  abstract isReady: boolean;
  abstract getAll(initialValue: T[]): Promise<T[]>;
  abstract setAll(value: T[]): Promise<void>;

  waitUntilReady = (timeout: number = 10000): Promise<void> => {
    if (this.isReady) {
      return Promise.resolve();
    }
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        if (this.isReady) {
          clearInterval(interval);
          resolve();
        }
      }, 100);
      setTimeout(() => {
        clearInterval(interval);
        if (!this.isReady) {
          throw new Error("ParcelStorageDB not ready");
        }
      }, timeout);
    });
  };
}
