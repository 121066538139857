import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  useTheme,
} from "@mui/material";
import { DrawerFooter, FleetDrawer } from "./Drawer.styled";

import { ATOMS } from "../../recoil/atom";
import Admin from "./DrawerSection/Admin";
import Config from "../../config/config";
import DataVisualization from "./DrawerSection/DataVisualization";
import Management from "./DrawerSection/Management";
import MobileApp from "./DrawerSection/MobileApp";
import Sav from "./DrawerSection/Sav";
import Tools from "./DrawerSection/Tools";
import { useAtomValue } from "jotai";

const Drawer = () => {
  const theme = useTheme();

  const open = useAtomValue(ATOMS.UI_DRAWER_OPEN);

  return (
    <FleetDrawer open={open} variant="permanent">
      <Toolbar />

      <Box
        height="100%"
        overflow="auto"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box
          sx={{
            overflowY: "auto",
            overflowX: "clip",
            ...(open && { pb: theme.spacing(2) }),
          }}
        >
          <DataVisualization open={open} />
          <Sav open={open} />
          <Management open={open} />
          <Admin open={open} />
          <Tools open={open} />
          <MobileApp open={open} />
        </Box>

        <DrawerFooter>
          {open ? <Divider key="footer-divider" /> : null}
          <List>
            <ListItem
              key="footer-version-item"
              sx={{
                m: 0,
                p: 0,
                // p: open ? theme.spacing(2) : 0,
                textAlign: "center",
              }}
            >
              <ListItemText
                secondary={`${Config.PACKAGE_VERSION}`}
                sx={{
                  minWidth: "unset",
                }}
              />
            </ListItem>
          </List>
        </DrawerFooter>
      </Box>
    </FleetDrawer>
  );
};

export default Drawer;
