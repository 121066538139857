import { Brightness4, Brightness7 } from "@mui/icons-material";

import { ATOMS } from "../../recoil/atom";
import { IconButton } from "@mui/material";
import { UI_THEMES } from "../../constants/theme";
import { useAtom } from "jotai";
import { useCallback } from "react";

const ThemeSwitcher = () => {
  const [uiTheme, setUiTheme] = useAtom(ATOMS.UI_THEME);

  const toggleTheme = useCallback(() => {
    const newTheme =
      uiTheme === UI_THEMES.DARK ? UI_THEMES.LIGHT : UI_THEMES.DARK;
    setUiTheme(newTheme);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uiTheme]);

  return (
    <IconButton onClick={toggleTheme} color="inherit" size="large">
      {uiTheme === UI_THEMES.LIGHT ? <Brightness4 /> : <Brightness7 />}
    </IconButton>
  );
};

export default ThemeSwitcher;
