import { collection, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";

import { ATOMS } from "../../recoil/atom";
import { ISimpleUser } from "../../types/user";
import { createSnackBarContent } from "../global/useSnackBar";
import { fireDatabase } from "../../config/firebase";
import { needRequestCollections } from "../../constants/access";
import { sortUsersList } from "../../utils/user";
import useNeedRequestVisibilityHelper from "../data/useNeedRequestVisibilityHelper";
import { useSetAtom } from "jotai";
import { useTranslation } from "react-i18next";

const collectionRequired = [needRequestCollections.USERS];

const useUser = () => {
  const { t } = useTranslation(["snackbar"]);

  const popSnackbar = useSetAtom(ATOMS.SNACKBAR);
  const setUserList = useSetAtom(ATOMS.USER_LIST);
  const setDisabledUserList = useSetAtom(ATOMS.USER_DISABLED_LIST);

  const [unSubscribe, setUnSubscribe] = useState<(() => void) | null>(null);

  const canLoadData = useNeedRequestVisibilityHelper(() => {
    unSubscribe && unSubscribe();
  }, collectionRequired);

  // User list
  useEffect(() => {
    if (canLoadData) {
      const unsub = onSnapshot(
        collection(fireDatabase, "users"),
        (snap) => {
          const enabledUserList: ISimpleUser[] = [];
          const disabledUserList: ISimpleUser[] = [];
          const devUserList: ISimpleUser[] = [];

          snap.docs.forEach((doc) => {
            const userData = doc.data();
            const userElement: ISimpleUser = {
              id: userData.id,
              name: userData.name,
              roles: userData.access_roles,
              nbRobots: userData.robot_list.length,
            };
            if (userData.disabled) {
              disabledUserList.push(userElement);
            } else {
              enabledUserList.push(userElement);
              if (
                Array.isArray(userElement.roles) &&
                userElement.roles.includes("dev")
              ) {
                devUserList.push(userElement);
              }
            }
          });

          enabledUserList.sort(sortUsersList);
          disabledUserList.sort(sortUsersList);

          setUserList(enabledUserList);
          setDisabledUserList(disabledUserList);

          devUserList.sort(sortUsersList);
        },
        (err) => {
          console.error(err);
          popSnackbar(
            createSnackBarContent({
              content: t("snackbar:request_users_error"),
              severity: "error",
            }),
          );
          setUserList([]);
          setDisabledUserList([]);
        },
      );
      setUnSubscribe(() => unsub);
      return () => unsub();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canLoadData]);

  return null;
};

export default useUser;
