import { collection, onSnapshot, query } from "firebase/firestore";
import { useEffect, useState } from "react";

import { ATOMS } from "../../recoil/atom";
import { IParcelMetaWithId } from "../../types/parcel";
import { createSnackBarContent } from "../global/useSnackBar";
import { fireDatabase } from "../../config/firebase";
import { needRequestCollections } from "../../constants/access";
import useNeedRequestVisibilityHelper from "../data/useNeedRequestVisibilityHelper";
import { useSetAtom } from "jotai";
import { useTranslation } from "react-i18next";

const collectionRequired = [needRequestCollections.PARCELS];

const useParcels = () => {
  const { t } = useTranslation(["snackbar"]);

  const popSnackbar = useSetAtom(ATOMS.SNACKBAR);
  const setParcelsList = useSetAtom(ATOMS.PARCELS_LIST);

  const [unSubscribe, setUnSubscribe] = useState<(() => void) | null>(null);

  const canLoadData = useNeedRequestVisibilityHelper(() => {
    unSubscribe && unSubscribe();
  }, collectionRequired);

  // Parcel list
  useEffect(() => {
    if (canLoadData) {
      const unsub = onSnapshot(
        query(collection(fireDatabase, "parcelMetas")),
        (snap) => {
          const snapMap = snap.docs.map(
            (doc) =>
              ({
                ...doc.data(),
                docID: doc.id,
              }) as IParcelMetaWithId,
          );
          snapMap.sort((a, b) =>
            a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
          );
          setParcelsList(snapMap);
        },
        (err) => {
          console.error(err);
          popSnackbar(
            createSnackBarContent({
              content: t("snackbar:request_parcels_error"),
              severity: "error",
            }),
          );
          setParcelsList([]);
        },
      );
      setUnSubscribe(() => unsub);
      return () => unsub();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canLoadData]);

  return null;
};

export default useParcels;
