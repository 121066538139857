import {
  AccountTree,
  AdminPanelSettings,
  Assessment,
  Dashboard,
  Downloading,
  EditLocation,
  Equalizer,
  Group,
  Info,
  List,
  Lock,
  Map,
  Memory,
  Settings,
  Smartphone,
  Storefront,
  SwapHoriz,
  Timelapse,
  Wifi,
} from "@mui/icons-material";

import BakusIcon from "../utils/img/BakusIcon";
import { SvgIcon } from "@mui/material";
import { TDrawerElement } from "../types/menu";
import ToolsIcon from "../utils/img/ToolsIcon";

export const DRAWER_WIDTH = 240;

export const DRAWER_MENU: { [key: string]: TDrawerElement } = {
  OVERVIEW: {
    name: "overview:overview_label",
    icon: <Equalizer />,
    path: "/data-visualisation/overview",
  },
  ERROR_REPORT: {
    name: "report:error_report_label",
    icon: <Timelapse />,
    path: "/data-visualisation/status-report",
  },
  ACTIVITY: {
    name: "activity:activity_label",
    icon: <Assessment />,
    path: "/data-visualisation/activity",
  },
  // SAV
  SAV_DASHBOARD: {
    name: "sav:sav_dashboard_label",
    icon: <Dashboard />,
    path: "/sav/dashboard",
  },
  SAV_HARDWARE_VERSION: {
    name: "sav:hardware_version_label",
    icon: <Memory />,
    path: "/sav/hardware-version",
  },
  SAV_FULLY_MANAGER: {
    name: "common:fully_interface",
    icon: <Smartphone />,
    path: "/sav/phone-sav",
  },
  // Management
  USERS_MANAGEMENT: {
    name: "user:users_label",
    icon: <Group />,
    path: "/management/users",
    badgeColor: "error",
  },
  ROBOTS_MANAGEMENT: {
    name: "common:robots_label",
    icon: (
      <SvgIcon viewBox="0 0 68.03 68.03">
        <path d={BakusIcon} />
      </SvgIcon>
    ),
    path: "/management/robots",
  },
  PARCELS_MANAGEMENT: {
    name: "common:parcels_label",
    icon: <Map />,
    path: "/management/parcels",
  },
  MAP_MANAGEMENT: {
    name: "overview:robot_map",
    icon: <EditLocation />,
    path: "/management/map",
  },
  ACCESS_MANAGEMENT: {
    name: "common:access_label",
    icon: <Lock />,
    path: "/admin/access",
  },
  STATUS_CODES_MANAGEMENT: {
    name: "status:status_code",
    icon: <Info />,
    path: "/management/status-codes",
  },
  LOGS_MANAGEMENT: {
    name: "logs:logs_label",
    icon: <List />,
    path: "/management/logs",
  },
  VERSIONS_MANAGEMENT: {
    name: "overview:versions_label",
    icon: <Downloading />,
    path: "/management/versions",
  },
  DATABASE_MANAGEMENT: {
    name: "overview:database_label",
    icon: <AdminPanelSettings />,
    path: "/admin/database",
  },
  DISTRIBUTOR_MANAGEMENT: {
    name: "distributor:distributor_label",
    icon: <Storefront />,
    path: "/management/distributor",
  },

  WIFI_CONFIG: {
    name: "wifi:wifi_config_label",
    icon: <Wifi />,
    path: "/admin/wifi-config",
  },

  // Tools
  TOOLS_CONFIG: {
    name: "tools:tools_config_label",
    icon: <AccountTree />,
    path: "/tools-management/tools-config",
  },
  TOOLS_MODELS: {
    name: "tools:tools_model_label",
    icon: <ToolsIcon />,
    path: "/tools-management/tools-models",
  },
  PARCEL_EXTREMITY_GROUP: {
    name: "row_extremity:parcel_extremity_group_label",
    icon: <SwapHoriz />,
    path: "/tools-management/parcel-extremity-groups",
  },

  // Mobile app
  GUI: {
    name: "config:config_label",
    icon: <Settings />,
    path: "/mobile-app/config",
  },
} as const;
