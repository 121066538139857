import { styled } from "@mui/material";

export const UserNameContainer = styled("div", {
  name: "UserNameContainer",
})(({ theme }) => ({
  textTransform: "capitalize",
  marginTop: -2,
  marginRight: 8,
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));
