import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { useEffect, useState } from "react";

import { ATOMS } from "../../recoil/atom";
import { IDistributor } from "../../types/distributor";
import { fireDatabase } from "../../config/firebase";
import { needRequestCollections } from "../../constants/access";
import useNeedRequestVisibilityHelper from "../data/useNeedRequestVisibilityHelper";
import { useSetAtom } from "jotai";

const collectionRequired = [needRequestCollections.DISTRIBUTORS];

const useDistributorsList = () => {
  const setDistributors = useSetAtom(ATOMS.DISTRIBUTOR_LIST);

  const [unSubscribe, setUnSubscribe] = useState<(() => void) | null>(null);

  const canLoadData = useNeedRequestVisibilityHelper(() => {
    unSubscribe && unSubscribe();
  }, collectionRequired);

  useEffect(() => {
    if (canLoadData) {
      const unsub = onSnapshot(
        query(collection(fireDatabase, "distributors"), orderBy("name")),
        (snapshot) => {
          const newList: IDistributor[] = [];
          snapshot.forEach((doc) => {
            const data = doc.data();
            newList.push({
              id: doc.id,
              name: data.name,
            });
          });
          setDistributors(newList);
        },
      );

      setUnSubscribe(() => unsub);
      return () => unsub();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canLoadData]);

  return null;
};

export default useDistributorsList;
