import { ISimpleUser } from "../types/user";
import { isValidNotEmptyString } from "./string";

export const sortUsersList = (
  a: ISimpleUser,
  b: ISimpleUser,
  order?: "asc" | "desc",
  orderBy?: string,
) => {
  /**
   * Order - ASC
   */
  if (!order || order === "asc") {
    // Default
    if (!orderBy) {
      return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
    }
    // Order by
    if (a[orderBy] > b[orderBy]) {
      return 1;
    } else if (b[orderBy] > a[orderBy]) {
      return -1;
    }
    return 0;
  } else {
    /**
     * Order - DESC
     */
    // Default
    if (!orderBy) {
      return a.name < b.name ? 1 : b.name < a.name ? -1 : 0;
    }
    // Order by
    if (a[orderBy] < b[orderBy]) {
      return 1;
    } else if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    return 0;
  }
};

export const getCleanUserName = (name: string | undefined | null) => {
  if (isValidNotEmptyString(name)) {
    if (name.indexOf("@") !== -1) {
      return name.slice(0, name.indexOf("@"));
    } else {
      return name;
    }
  } else {
    return "";
  }
};
