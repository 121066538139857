import { Badge, ListItemIcon } from "@mui/material";
import { MenuElement, MenuElementText } from "./MenuItem.styled";
import { useMatch, useNavigate } from "react-router-dom";

import { TDrawerElement } from "../../types/menu";
import { isDefined } from "../../utils/object";
import { useTranslation } from "react-i18next";

type IMenuItemProps = {
  element: TDrawerElement;
  open: boolean;
};

const MenuItem = ({ element, open }: IMenuItemProps) => {
  const { t } = useTranslation([
    "common",
    "overview",
    "report",
    "activity",
    "distributor",
    "sav",
    "user",
    "status",
    "tools",
    "row_extremity",
    "config",
    "logs",
    "wifi",
  ]);
  const navigate = useNavigate();
  const match = useMatch({
    end: false,
    path: element.path,
  });

  return (
    <>
      <MenuElement
        onClick={() => navigate(element.path, { replace: true })}
        onAuxClick={() => window.open(element.path, "_blank")}
        selected={match !== null}
        data-to={element.path}
        dense
        hasBadge={isDefined(element.badgeCount)}
      >
        <ListItemIcon>
          {isDefined(element.badgeCount) ? (
            <Badge badgeContent={element.badgeCount} color={element.badgeColor}>
              {element.icon}
            </Badge>
          ) : (
            element.icon
          )}
        </ListItemIcon>

        <MenuElementText primary={t(element.name)} open={open} />
      </MenuElement>
    </>
  );
};

export default MenuItem;
