import { Box, styled } from "@mui/material";

import PAGE from "../../constants/page";

const PageContentWrapperRoot = styled("div", {
  name: "PageContentWrapperRoot",
})({
  flex: 1,
  width: "100%",
  overflow: "auto",
  margin: "0 auto",
  padding: `${PAGE.CONTENT.SPACING}px`,
});

const PageContentWrapperContainer = styled(Box, {
  name: "PageContentWrapperContainer",
  shouldForwardProp: (prop) => prop !== "fullWidth",
})<{ fullWidth?: boolean }>(({ fullWidth = false }) => ({
  margin: "0 auto",
  maxWidth: fullWidth === true ? "auto" : PAGE.CONTENT.WIDTH,
}));

type PageContentWrapperProps = {
  children: React.ReactNode;
  spacing?: number;
  fullWidth?: boolean;
  className?: string;
};

const PageContentWrapper = (props: PageContentWrapperProps) => {
  return (
    <PageContentWrapperRoot>
      <PageContentWrapperContainer fullWidth={props.fullWidth}>
        {props.children}
      </PageContentWrapperContainer>
    </PageContentWrapperRoot>
  );
};

export default PageContentWrapper;
