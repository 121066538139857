import { Navigate, Route, Routes as Switch } from "react-router-dom";
import { Suspense, lazy } from "react";

import AppBar from "./AppBar";
import { Box } from "@mui/material";
import Drawer from "./Drawer";
import FixedSpinner from "./FixedSpinner";
import { FleetPageContainer } from "./FleetScaffold.styled";
import NotFoundPage from "../../pages/NotFoundPage";
import PAGE from "../../constants/page";
import { PAGE_FALLBACK } from "../../constants/access";
import SnackBar from "../SnackBar";
import { SnackbarProvider } from "notistack";
import useWindowDimensions from "../../hooks/global/useWindowDimensions";

const DataVisualization = lazy(() => import("./AppPart/DataVisualization"));
const Sav = lazy(() => import("./AppPart/Sav"));
const Management = lazy(() => import("./AppPart/Management"));
const Admin = lazy(() => import("./AppPart/Admin"));
const Tools = lazy(() => import("./AppPart/Tools"));
const MobileApp = lazy(() => import("./AppPart/MobileApp"));

const FleetScaffold = () => {
  const { height } = useWindowDimensions();

  return (
    <SnackbarProvider maxSnack={5}>
      <FleetPageContainer height={height}>
        <SnackBar />
        <AppBar />
        <Drawer />

        <Box
          component="main"
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            marginTop: `${PAGE.APP_BAR_HEIGHT}px`,
            overflow: "auto",
          }}
        >
          <Switch>
            <Route
              path="/data-visualisation/*"
              element={
                <Suspense fallback={<FixedSpinner />}>
                  <DataVisualization />
                </Suspense>
              }
            />

            <Route
              path="/sav/*"
              element={
                <Suspense fallback={<FixedSpinner />}>
                  <Sav />
                </Suspense>
              }
            />

            <Route
              path="/management/*"
              element={
                <Suspense fallback={<FixedSpinner />}>
                  <Management />
                </Suspense>
              }
            />

            <Route
              path="/admin/*"
              element={
                <Suspense fallback={<FixedSpinner />}>
                  <Admin />
                </Suspense>
              }
            />

            <Route
              path="/tools-management/*"
              element={
                <Suspense fallback={<FixedSpinner />}>
                  <Tools />
                </Suspense>
              }
            />

            <Route
              path="/mobile-app/*"
              element={
                <Suspense fallback={<FixedSpinner />}>
                  <MobileApp />
                </Suspense>
              }
            />

            <Route
              path="/access-denied"
              element={
                <Suspense fallback={<FixedSpinner />}>
                  <NotFoundPage denied />
                </Suspense>
              }
            />

            <Route
              key={"default route"}
              path="/"
              element={<Navigate to={PAGE_FALLBACK} replace />}
            />

            <Route key={"redirect route"} path="*" element={<NotFoundPage />} />
          </Switch>
        </Box>

        <div data-cy="logged-div" />
      </FleetPageContainer>
    </SnackbarProvider>
  );
};

export default FleetScaffold;
