import { APP_BAR_TITLE_DEFAULT_VALUE, ATOMS } from "../../recoil/atom";
import { AppTitle, AppToolbar, FleetAppBar, MenuIcon } from "./AppBar.styled";
import { Close, Menu } from "@mui/icons-material";
import { useAtom, useAtomValue } from "jotai";

import LocalesBtn from "./LocalesBtn";
import SignOutButton from "./SignOutButton";
import ThemeSwitcher from "../Menu/ThemeSwitcher";
import UserName from "./UserName";
import { useCallback } from "react";
import { useTheme } from "@mui/styles";
import { useTranslation } from "react-i18next";

const AppBar = () => {
  const { t } = useTranslation(["app_bar_title"]);
  const { palette } = useTheme();

  const [open, setOpen] = useAtom(ATOMS.UI_DRAWER_OPEN);

  const title = useAtomValue(ATOMS.UI_APP_BAR_CONTENT);

  const toggleDrawer = useCallback(() => {
    setOpen(!open);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <FleetAppBar position="fixed" elevation={palette.mode === "dark" ? 1 : 0}>
      <AppToolbar disableGutters>
        <MenuIcon
          id="drawerIconOpen"
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer}
          size="large"
        >
          {open ? <Close /> : <Menu />}
        </MenuIcon>
        <AppTitle variant="h6">
          {title === APP_BAR_TITLE_DEFAULT_VALUE
            ? // Default AppBar title value is not translated
              t(title)
            : // Others are already translated by the useAppBarTitle hook
              title}
        </AppTitle>
        <UserName />
        <ThemeSwitcher />
        <LocalesBtn color="inherit" />
        <SignOutButton />
      </AppToolbar>
    </FleetAppBar>
  );
};

export default AppBar;
